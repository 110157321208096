
import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import './style/signupform.css';
import './style/appointmentbooking.css';
import './style/demoheader.css';
import './style/BannerAssets.css';
import './style/intakeform.css';
import './style/quickintake.css';
import './style/mediabanners.css';
// import Footer from './Layout/Footer';
// import Header from './Layout/Header';
// import Home from './Components/Home';
// import SymtomForm from './Components/SymtomForm';
// import NoPage from './Components/Nopage';
// import IntakeForm from './Components/IntakeForm';
// import SignUpFormLight from './Components/Lighttheme/SignUpForm';

import { Route, BrowserRouter, Routes, useParams, useLocation, Navigate } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import { fetchPracticeDetails, setInitialData } from './Components/practiceReducer';
import { useDispatch, useSelector } from 'react-redux';
// import { Route, BrowserRouter, Routes, useParams } from 'react-router-dom'
// import { AppointmentBooking } from './Components/Appointment-Booking/AppointmentBooking';
import { setInitialDataFromCookie } from './Components/SignupFormReducer';
// import { ThankyouPage } from './Components/thankyou';
import { GcalThankyouPage } from './Components/gcalthankyou';
import store from './store';
import { LinearProgress, Snackbar } from '@mui/material';
import { setLogedinUserInfo } from './Components/Share4rewards/RewardsLogin/RewardLoginReducer';
import Diabatics from './Components/Diabatics/Diabatics';
import DiabaticsPayment from './Components/Diabatics/DiabaticsPayment';

import Terms from './Components/Terms-and-Conditions/Terms';
import Privacy from './Components/Privacy-Policy/Privacy';


import Footer from './Layout/Footer';
// import Share4rewards from './Components/Share4rewards/Share4rewards';

// import { SlotCancel } from './Components/Slot-Cancel/SlotCancel';
// import { QuickIntakeForm } from './Components/quickintake';
// import BannerAssets from './Components/BannerAssets';
// import MediaBanner from './Components/mediabanners';
// import Lightthemenew from './Components/Lighttheme_new/lightthemenew';
// import Lightthemenewdemo from './Components/demoheaderfooter/lightthemenewdemo';
// import { Contract } from './Components/Contracted/Contract';
// import Landingnew from './Components/landingnew';
// import { SuccessPage } from './Components/Success';


const Home = React.lazy(() => import('./Components/Home'));
const SymtomForm = React.lazy(() => import('./Components/SymtomForm'));
const SignUpFormLight = React.lazy(() => import('./Components/Lighttheme/SignUpForm'));
const Lightthemenew = React.lazy(() => import('./Components/Lighttheme_new/lightthemenew'));
const Lightthemenewdemo = React.lazy(() => import('./Components/demoheaderfooter/lightthemenewdemo'));
const BannerAssets = React.lazy(() => import('./Components/BannerAssets'));
const MediaBanner = React.lazy(() => import('./Components/mediabanners'));
const Landingnew = React.lazy(() => import('./Components/landingnew'));
const QuickIntakeForm = React.lazy(() => import('./Components/quickintake'));
const SlotCancel = React.lazy(() => import('./Components/Slot-Cancel/SlotCancel'));
const AppointmentBooking = React.lazy(() => import('./Components/Appointment-Booking/AppointmentBooking'));
// const {setInitialDataFromCookie} = React.lazy(() => import('./Components/SignupFormReducer'));
const ThankyouPage = React.lazy(() => import('./Components/thankyou'));
const Contract = React.lazy(() => import('./Components/Contracted/Contract'));
const SuccessPage = React.lazy(() => import('./Components/Success'));
const NoPage = React.lazy(() => import('./Components/Nopage'));
const IntakeForm = React.lazy(() => import('./Components/IntakeForm'));
const Healthriskexam = React.lazy(() => import('./Components/Healthriskexam/Healthriskexam'));
const Share4rewards = React.lazy(() => import('./Components/Share4rewards/Share4rewards'));
const RewardsContract = React.lazy(() => import('./Components/Share4rewards/RewardsContract'));
const RewardsLogin = React.lazy(() => import('./Components/Share4rewards/RewardsLogin/RewardLogin'));
const RewardsDashboard = React.lazy(() => import('./Components/Share4rewards/RewardDashboard/RewardDashboard'));
const MyAccount = React.lazy(() => import('./Components/Share4rewards/MyAccount/MyAccount'));
const RewardResetPassword = React.lazy(() => import("./Components/Share4rewards/RewardsLogin/RewardResetPassword"));
const RewardForgotPassword = React.lazy(() => import("./Components/Share4rewards/RewardsLogin/RewardForgotPassword"));
const RewardChangePassword = React.lazy(() => import("./Components/Share4rewards/RewardsLogin/RewardChangePassword"));



function App() {
  // const params = useParams()
  // const { _id } = params
  // console.warn(_id);
  // console.warn(process.env.REACT_APP_PROJECT_ENV);
  // console.warn(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL);
  const dispatch = useDispatch();

  const params = useParams();
  const { paramUniqueCode } = params;

  console.log("params======================>", window.location.href)

  const [techIdset, settechIdsCookie] = useCookies(["techIds"]);
  const [practiceIdset, setPracticeIdCookie] = useCookies(["practiceIds"]);
  const [themeSet, setThemeCookie] = useCookies(["theme"]);
  const [userInfo, setuserInfoCookie] = useCookies(["user_details"]);

  const [isExitIntent, setExitIntent] = useState(false);
  const [practiceDetails, setPracticeDetails] = useState('');
  const [lightBoxModal, setLightBoxModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [isMailFired, setIsMailFired] = useState(false);
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [isMobileDeviceExit, setIsMobileDeviceExit] = useState(false);
  const [is_healthrisk, setishealthrisk] = useState(false);
  const [is_shareforrewards, setisshareforrewards] = useState(false);
  const [is_pecemarketing, setispecemarketing] = useState(false);
  const [practiceLocationData, setPracticeLocationData] = useState(null);
  const [is_diabetesrexburg, setisdiabatics] = useState(false);

  const currDomain = process.env.REACT_APP_PROJECT_NAME;

  const uniqueCodeLocationDetails = useSelector((state) => state.practiceReducer && state.practiceReducer.uniqueCodeLocationDetails ? state.practiceReducer.uniqueCodeLocationDetails : null);
  const practiceLocationDetails = useSelector((state) => state.practiceReducer && state.practiceReducer.practiceDetails ? state.practiceReducer.practiceDetails : null);


  useEffect(() => {
    if (uniqueCodeLocationDetails && practiceLocationDetails) {
      if (uniqueCodeLocationDetails.length > 0 && practiceLocationDetails.location_details && practiceLocationDetails.location_details.length > 0) {
        practiceLocationDetails.location_details.forEach((e, i) => {
          if (e.location_name == uniqueCodeLocationDetails[0].location_name) {
            setPracticeLocationData(e)
            if (document.getElementById("practiceLogo") !== null) {
              document.getElementById("practiceLogo").innerHTML = `<img src=${store.getState().practiceReducer.practiceDetails.img_url} />`
              // document.getElementById("practiceAddress").innerHTML = `${storeData.practiceReducer.practiceDetails.location_details[0].city},  ${storeData.practiceReducer.practiceDetails.location_details[0].state}, ${storeData.practiceReducer.practiceDetails.location_details[0].zip}`;
              document.getElementById("practiceAddress").innerHTML = `${e.city},  ${e.state}, ${e.zip}`;
              // document.getElementById("practicePhone").innerHTML = `${storeData.practiceReducer.practiceDetails.phone}`;

              var practiceAddressVar = document.getElementById('practiceAddress');
              // practiceAddressVar.setAttribute('title', `${storeData.practiceReducer.practiceDetails.location_details[0].city},  ${storeData.practiceReducer.practiceDetails.location_details[0].state}, ${storeData.practiceReducer.practiceDetails.location_details[0].zip}`);
              practiceAddressVar.setAttribute('title', `${e.city},  ${e.state}, ${e.zip}`);
              var practicePhoneVar = document.getElementById('practicePhone');
              var practicePhoneAnchor = document.createElement('a');
              // practicePhoneAnchor.setAttribute('href', `tel:${storeData.practiceReducer.practiceDetails.phone}`);
              practicePhoneAnchor.setAttribute('href', `tel:${e.phone_no}`);
              practicePhoneAnchor.setAttribute('style', `text-decoration: none;color: inherit;`);
              // practicePhoneAnchor.innerHTML = `${storeData.practiceReducer.practiceDetails.phone}`;
              practicePhoneAnchor.innerHTML = `${e.phone_no}`;
              console.log(" practicePhoneVar", practicePhoneAnchor.innerHTML)
              if (practicePhoneVar.innerHTML === '') {
                practicePhoneVar.appendChild(practicePhoneAnchor);
              }
            }
          }
        })
      }

    }

  }, [uniqueCodeLocationDetails, practiceLocationDetails]);
  // console.log("uniqueCodeLocationDetails app", uniqueCodeLocationDetails);

  useEffect(() => {
    // console.log("currDomain", window.location.href)
    if (window.location.href.includes(":4300")) setishealthrisk(true);

    if (window.location.href.includes("healthriskexam.com"))
      setishealthrisk(true);

    if (window.location.href.includes(":4400")) setisshareforrewards(true);

    if (window.location.href.includes("share4rewards.com"))
      setisshareforrewards(true);

    if (window.location.href.includes(":3000")) setispecemarketing(true);

    if (window.location.href.includes("qualitypracticeweb.com"))
      setispecemarketing(true);

    if (window.location.href.includes("marketing.peceportal.com"))
      setispecemarketing(true);

    if (window.location.href.includes(":4500")) setisdiabatics(true);

    if (window.location.href.includes("diabetesrexburg.com"))
      setisdiabatics(true);

  }, [])

  // console.log("currDomain", currDomain)
  // const location = useLocation();
  // console.log("from appointment booking >>>", location.pathname);
  const [isDemo, setDemo] = useState(null);
  const [isDemoPage, setIsDemoPage] = useState(false);

  useEffect(() => {
    // settechIdsCookie("techIds", techId, { path: "/" })
    // setPracticeIdCookie("practiceIds", paramUniqueCode, { path: "/" })

    // if(location.pathname.includes("/lighttheme")){
    //    setThemeCookie("theme", 'lighttheme', { path: "/" })
    // }


    const cookies = new Cookies();

    // let cookieval = cookies.get('techIds')
    let cookievalpractice = cookies.get('practiceIds')
    let cookieDarkTheme = cookies.get('theme')
    let cookieFormSubmitData = cookies.get('formsubmitdata')


    // console.warn("theme", cookieDarkTheme)
    // console.warn("techIds goes here", cookieval )
    // console.warn("cookievalpractice goes here", cookievalpractice)

    dispatch(setInitialData({ techIds: [], practiceIds: cookievalpractice, theme: cookieDarkTheme }))

    dispatch(setInitialData({ techIds: [], practiceIds: cookievalpractice, theme: cookieDarkTheme }))
    dispatch(setInitialDataFromCookie({ formData: (cookieFormSubmitData !== 'undefined' ? cookieFormSubmitData : {}) }))

  }, []);

  //  useEffect(() => {
  //     if (location.pathname.includes("demo")) {
  //       setDemo(1);
  //       setIsDemoPage(true);
  //     }
  //   }, []);
  useEffect(() => {

    if (is_pecemarketing && !is_diabetesrexburg) {
      const exit = e => {
        const shouldExit =
          [...e.target.classList].includes('exit-intent-popup') || // user clicks on mask
          e.target.className === 'close' || // user clicks on the close icon
          e.keyCode === 27; // user hits escape

        if (shouldExit) {
          document.querySelector('.exit-intent-popup').classList.remove('visible');
          setExitIntent(false);
          setIsMobileDeviceExit(false);
        }
      };
      setTimeout(() => {
        // document.addEventListener('mouseout', mouseEvent);
        document.addEventListener('keydown', exit);
        document.querySelector('.exit-intent-popup').addEventListener('click', exit);
      }, 0);
    }
  }, []);

  window.onblur = function () {
    if (!window.location.pathname.includes('bannerassets') && !window.location.pathname.includes('mediabanner') && !window.location.pathname.includes('404') && !window.location.pathname.includes('thankyou') && !window.location.pathname.includes('success')) {
      if (is_pecemarketing && !is_diabetesrexburg) {
        setExitIntent(true);
        setLightBoxModal(true);
        setIsMobileDeviceExit(true);
      }
    }
  }
  window.onbeforeunload = confirmExit;
  function confirmExit() {
    if (!window.location.pathname.includes('bannerassets') && !window.location.pathname.includes('mediabanner') && !window.location.pathname.includes('404') && !window.location.pathname.includes('thankyou') && !window.location.pathname.includes('success') && is_pecemarketing && !is_diabetesrexburg) {
      return "Some task is in progress. Are you sure, you want to close?";
    }
  }
  function mouseEvent(e) {
    // let shouldShowExitIntent =
    //   !e.toElement &&
    //   !e.relatedTarget &&
    //   e.clientY < 10 && !window.location.pathname.includes('bannerassets') && !window.location.pathname.includes('mediabanner') && !window.location.pathname.includes('404') && !window.location.pathname.includes('thankyou') && !window.location.pathname.includes('success')
    // setExitIntent(shouldShowExitIntent);
  };

  useEffect(() => {
    if (isExitIntent && lightBoxModal && !is_diabetesrexburg) {
      document.removeEventListener('mouseout', mouseEvent);
      document.querySelector('.exit-intent-popup').classList.add('visible');
    }
  }, [isExitIntent, lightBoxModal, is_diabetesrexburg]);


  useEffect(() => {
    if (!isExitIntent && is_pecemarketing) {
      console.log("practiceIdset", practiceIdset)
      if (practiceIdset && practiceIdset.practiceIds && practiceIdset.practiceIds !== "undefined") {
        if (Object.keys(practiceDetails).length === 0) {
          const reqData = {
            _id: practiceIdset.practiceIds

          }
          dispatch(fetchPracticeDetails(reqData))
        }
      }
    }
  }, [isExitIntent, is_pecemarketing, practiceIdset]);
  useEffect(() => {
    if (is_diabetesrexburg) {

      // console.log("practiceIdset", practiceIdset)
      if (Object.keys(practiceDetails).length === 0) {
        const reqData = {
          _id: "654b177b0d1ca08f9a3f4d2c"

        }
        // dispatch(fetchPracticeDetails(reqData))    
      }
    }
  }, [is_diabetesrexburg])

  function sendEmailEvent() {
    // console.log("practiceDetails email 444", practiceDetails ,"practiceIdset ", store.getState().practiceReducer.practiceDetails)
    let linearProgressTag = document.createElement("div");
    linearProgressTag.classList.add('progressbarDiv');
    linearProgressTag.innerHTML = `<span class="MuiLinearProgress-root MuiLinearProgress-colorPrimary MuiLinearProgress-indeterminate css-eglki6-MuiLinearProgress-root" role="progressbar"><span class="MuiLinearProgress-bar MuiLinearProgress-barColorPrimary MuiLinearProgress-bar1Indeterminate css-5ir5xx-MuiLinearProgress-bar1"></span><span class="MuiLinearProgress-bar MuiLinearProgress-barColorPrimary MuiLinearProgress-bar2Indeterminate css-1r8wrcl-MuiLinearProgress-bar2"></span></span>`
    let emailContainer = document.querySelector('.innerContainer');
    emailContainer.appendChild(linearProgressTag)

    let emailText = document.querySelector('#emailInput').value;
    const reqData = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        practice_id: store.getState().practiceReducer.practiceDetails._id,
        emails: [emailText]
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "googleapi/get-mail-notification", reqData)
      .then((results) => results.json())
      .then((data) => {

        if (data && data.status === 'success') {
          document.getElementById("emailInput").value = '';
          document.getElementById('emailContainerId').classList.remove('open');
          setIsMailFired(true);
          setsnakbaropen(true);
          setSnackBarMsg("Email sent successfully!")
        }
        // console.log("emailText data", data,)
      });
  }

  useEffect(() => {
    if (!isMailFired && is_pecemarketing && !is_diabetesrexburg) {
      document.querySelector('#sendEmail').addEventListener('click', sendEmailEvent);

      // document.querySelector('#sendEmail').innerHTML = `${<LinearProgress  />}`
    }
  }, [isMailFired, is_pecemarketing]);

  useEffect(() => {
    if (snakbaropen) {
      setTimeout(() => {
        setsnakbaropen(false);
      }, 2000)
    }
  }, [snakbaropen]);

  useEffect(() => {
    if (is_pecemarketing) {
      let hasTouchScreen = false;
      if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else {
        const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
          hasTouchScreen = !!mQ.matches;
        } else if ("orientation" in window) {
          hasTouchScreen = true; // deprecated, but good fallback
        } else {
          // Only as a last resort, fall back to user agent sniffing
          var UA = navigator.userAgent;
          hasTouchScreen =
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
      }
      if (hasTouchScreen) {
        window.onblur = function () {
          // do some stuff after tab was changed e.g.

          setExitIntent(true);
          setLightBoxModal(true);
          setIsMobileDeviceExit(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isMobileDeviceExit && !is_diabetesrexburg) {
      setTimeout(() => {
        var w = window.open('', '', 'width=1,height=1');
        // console.log("window w", w)
        if (w) {
          w.focus();
          setTimeout(function () { w.close(); }, 1)
        }
      }, 1)
    }
    // console.log("is_pecemarketing  ",isMobileDeviceExit)
  }, [isMobileDeviceExit]);


  useEffect(() => {
    if (userInfo && userInfo.user_details) {
      console.log("userInfo", userInfo)
      dispatch(setLogedinUserInfo(userInfo.user_details))
    }
  }, [])

  const useAuth = () => {
    // console.log("AUTHED++++++++++ ", userInfo)
    if (store.getState().rewardLoginReducer.isLoggedIn || (userInfo && userInfo.user_details)) {
      return true;
    }
  }

  const PrivateRoute = ({ children }) => {
    // console.log("authed loginDetails", userInfo);
    const auth = useAuth();
    return (
      auth === true ? children : <Navigate to="/login" replace={true} />
    )
  }





  // console.log("is_pecemarketing  ",isMobileDeviceExit)
  store.subscribe(() => {
    const storeData = store.getState();
    // console.log("uniqueCodeLocationDetails practiceLocationData", practiceLocationData)
    if (is_pecemarketing) {
      if (storeData.practiceReducer && storeData.practiceReducer.lightBoxModal && !is_diabetesrexburg) {
        setLightBoxModal(true)
      }
      if (storeData.practiceReducer && storeData.practiceReducer.practiceDetails && Object.keys(storeData.practiceReducer.practiceDetails).length > 0 && is_diabetesrexburg) {
        // console.log("emailText storeData.practiceReducer.practiceDetails", storeData.practiceReducer.practiceDetails)
        setPracticeDetails(storeData.practiceReducer.practiceDetails);
        // if (document.getElementById("practiceLogo") !== null && practiceLocationData) {
        //   document.getElementById("practiceLogo").innerHTML = `<img src=${storeData.practiceReducer.practiceDetails.img_url} />`
        //   // document.getElementById("practiceAddress").innerHTML = `${storeData.practiceReducer.practiceDetails.location_details[0].city},  ${storeData.practiceReducer.practiceDetails.location_details[0].state}, ${storeData.practiceReducer.practiceDetails.location_details[0].zip}`;
        //   document.getElementById("practiceAddress").innerHTML = `${practiceLocationData.city},  ${practiceLocationData.state}, ${practiceLocationData.zip}`;
        //   // document.getElementById("practicePhone").innerHTML = `${storeData.practiceReducer.practiceDetails.phone}`;

        //   var practiceAddressVar = document.getElementById('practiceAddress');
        //   // practiceAddressVar.setAttribute('title', `${storeData.practiceReducer.practiceDetails.location_details[0].city},  ${storeData.practiceReducer.practiceDetails.location_details[0].state}, ${storeData.practiceReducer.practiceDetails.location_details[0].zip}`);
        //   practiceAddressVar.setAttribute('title', `${practiceLocationData.city},  ${practiceLocationData.state}, ${practiceLocationData.zip}`);
        //   var practicePhoneVar = document.getElementById('practicePhone');
        //   var practicePhoneAnchor = document.createElement('a');
        //   // practicePhoneAnchor.setAttribute('href', `tel:${storeData.practiceReducer.practiceDetails.phone}`);
        //   practicePhoneAnchor.setAttribute('href', `tel:${practiceLocationData.phone_no}`);
        //   practicePhoneAnchor.setAttribute('style', `text-decoration: none;color: inherit;`);
        //   // practicePhoneAnchor.innerHTML = `${storeData.practiceReducer.practiceDetails.phone}`;
        //   practicePhoneAnchor.innerHTML = `${practiceLocationData.phone_no}`;
        //   console.log(" practicePhoneVar", practicePhoneAnchor.innerHTML)
        //   if (practicePhoneVar.innerHTML === '') {
        //     practicePhoneVar.appendChild(practicePhoneAnchor);
        //   }
        // }
      }
    }

  });



  // console.log("is_healthrisk", is_healthrisk)


  return (
    <React.Fragment>

      <BrowserRouter>
        <Routes>
          {/* <Route path='/:paramUniqueCode' element={<Home />} /> */}
          <Route path="/:paramUniqueCode/*" element={<Suspense fallback={<></>}><Home /></Suspense>} />
          {/* <Route path='/:practiceId/*' element={<Home />} /> */}
          <Route path="/:practiceId/*" element={<Suspense fallback={<></>}><Home /></Suspense>} />

          {/* <Route path='' index element={<Home />} /> */}
          {/* <Route path='' index element={<Home />} /> */}
          <Route path="" element={<Suspense fallback={<></>}><Home /></Suspense>} />
          {/* <Route path='home/:_id/:location' element={<Home />} /> */}
          <Route path="home/:_id/:location" element={<Suspense fallback={<></>}><Home /></Suspense>} />
          {/* <Route path='homedemo/:_id' element={<Home />} /> */}
          <Route path="homedemo/:_id" element={<Suspense fallback={<></>}><Home /></Suspense>} />


          {/* <Route path='demomobi/:_id' element={<Home />} /> */}
          <Route path="demomobi/:_id" element={<Suspense fallback={<></>}><Home /></Suspense>} />

          {/* <Route path='mediabanner' element={<MediaBanner />} /> */}
          <Route path="mediabanner" element={<Suspense fallback={<></>}><MediaBanner /></Suspense>} />
          {/* <Route path='mediabannernew' element={<MediaBanner />} /> */}
          <Route path="mediabannernew" element={<Suspense fallback={<></>}><MediaBanner /></Suspense>} />
          {/* <Route path='home-demo-two/:_id' element={<SignUpFormLight />} /> */}
          <Route path="home-demo-two/:_id" element={<Suspense fallback={<></>}><SignUpFormLight /></Suspense>} />
          {/* <Route path='symtom-form' element={<SymtomForm />} /> */}
          <Route path="symtom-form" element={<Suspense fallback={<></>}><SymtomForm /></Suspense>} />
          {/* <Route path='intake/:_id' element={<IntakeForm />} /> */}
          <Route path="intake/:_id" element={<Suspense fallback={<></>}><IntakeForm /></Suspense>} />
          {/* <Route path='quickintake/:_id' element={<QuickIntakeForm />} /> */}
          <Route path='quickintake/:_id' index element={<Suspense fallback={<></>}><QuickIntakeForm /></Suspense>} />
          {/* <Route path='quickintakedemo/:_id' element={<QuickIntakeForm />} /> */}
          <Route path='quickintakedemo/:_id' index element={<Suspense fallback={<></>}><QuickIntakeForm /></Suspense>} />

          <Route path='quick-intake/:intakecode' index element={<Suspense fallback={<></>}><QuickIntakeForm /></Suspense>} />

          {/* <Route path='lightthemenew/:_id' element={<Lightthemenew />} /> */}
          <Route path='lightthemenew/:_id' index element={<Suspense fallback={<></>}><Lightthemenew /></Suspense>} />

          {/* <Route path='lightthemenewdemo/:_id' element={<Lightthemenewdemo />} /> */}
          <Route path='lightthemenewdemo/:_id' index element={<Suspense fallback={<></>}><Lightthemenewdemo /></Suspense>} />


          {is_healthrisk || is_shareforrewards ?
            <> (<Route path="/:uniqueID" element={<Suspense fallback={<></>}><Home /></Suspense>} />) </> : ""
          }
          <Route path='share4rewards' element={<Suspense fallback={<></>}><Share4rewards /></Suspense>} />
          {/* <Route path='/:uniqueID' element={<Suspense fallback={<></>}><Share4rewards /></Suspense>} /> */}
          <Route path='rewards-contract/:_id' element={<Suspense fallback={<></>}><RewardsContract /></Suspense>} />
          <Route path='logged-rewards-contract/:_id' element={<Suspense fallback={<></>}><RewardsContract /></Suspense>} />
          <Route path='login' element={<Suspense fallback={<></>}><RewardsLogin /></Suspense>} />
          <Route path='dashboard' element={<PrivateRoute><Suspense fallback={<></>}><RewardsDashboard /></Suspense></PrivateRoute>} />
          <Route path='my-account' element={<PrivateRoute><Suspense fallback={<></>}><MyAccount /></Suspense></PrivateRoute>} />
          <Route path='change-password' element={<PrivateRoute><Suspense fallback={<></>}><RewardResetPassword /></Suspense></PrivateRoute>} />
          <Route path='set-password' element={<PrivateRoute><Suspense fallback={<></>}><RewardChangePassword /></Suspense></PrivateRoute>} />
          <Route path='forget-password' element={<Suspense fallback={<></>}><RewardForgotPassword /></Suspense>} />

          <Route path='reset-password/:identifier' element={<Suspense fallback={<></>}><RewardForgotPassword /></Suspense>} />
          {/* <Route path='footer' element={<Suspense fallback={<></>}><Footer /></Suspense>} /> */}

          {is_diabetesrexburg ?
            <> (<Route path="/:uniqueID" element={<Suspense fallback={<></>}><Home /></Suspense>} />) </> : ""
          }
          <Route path='diabatics' element={<Suspense fallback={<></>}><Diabatics /></Suspense>} />
          <Route path='payment/:patientID' element={<Suspense fallback={<></>}><DiabaticsPayment /></Suspense>} />



          <Route path='healthriskexam' element={<Suspense fallback={<></>}><Healthriskexam /></Suspense>} />
          {/* <Route path='/:uniquename' element={<Suspense fallback={<></>}><Healthriskexam /></Suspense>} /> */}



          {/* <Route path='/lighttheme/signup/:paramUniqueCode' element={<SignUpFormLight />} /> */}
          <Route path='/lighttheme/signup/:paramUniqueCode' index element={<Suspense fallback={<></>}><SignUpFormLight /></Suspense>} />
          {/* <Route path='/lighttheme/signup/:practiceId/*' exact element={<SignUpFormLight />} /> */}
          <Route path='/lighttheme/signup/:practiceId/*' index element={<Suspense fallback={<></>}><SignUpFormLight /></Suspense>} />
          {/* <Route path='/appointment-booking/:_id' element={<AppointmentBooking />} /> */}
          <Route path='/appointment-booking/:_id' index element={<Suspense fallback={<></>}><AppointmentBooking /></Suspense>} />
          {/* <Route path='/appointment-booking-tech/:techid/:practiceid/:address' element={<AppointmentBooking />} /> */}
          <Route path='/appointment-booking-tech/:techid/:practiceid/:address' index element={<Suspense fallback={<></>}><AppointmentBooking /></Suspense>} />
          {/* <Route path='/appointment-booking-demo/:_id' element={<AppointmentBooking />} /> */}
          <Route path='/appointment-booking-demo/:_id' index element={<Suspense fallback={<></>}><AppointmentBooking /></Suspense>} />
          {/* <Route path='/re-schedule-appointment/:identifier' element={<AppointmentBooking />} /> */}
          <Route path='/re-schedule-appointment/:identifier' index element={<Suspense fallback={<></>}><AppointmentBooking /></Suspense>} />
          {/* <Route path='/thankyou/:id/:page' element={<ThankyouPage />} /> */}
          <Route path='/thankyou/:id/:page' index element={<Suspense fallback={<></>}><ThankyouPage /></Suspense>} />
          {/* <Route path='/g-cal-thankyou' element={<GcalThankyouPage />} /> */}
          {/* <Route path='/cancel-slot/:id' element={<SlotCancel />} /> */}
          <Route path='/cancel-slot/:id' index element={<Suspense fallback={<></>}><SlotCancel /></Suspense>} />
          {/* <Route path='bannerassets' element={<BannerAssets />} /> */}
          <Route path='bannerassets' index element={<Suspense fallback={<></>}><BannerAssets /></Suspense>} />
          {/* <Route path='/contract/:patientID' element={<Contract />} /> */}
          <Route path='/contract/:patientID' index element={<Suspense fallback={<></>}><Contract /></Suspense>} />
          {/* <Route path='/sucess' element={<SuccessPage />} /> */}
          <Route path='/success/:id' index element={<Suspense fallback={<></>}><SuccessPage /></Suspense>} />
          <Route path='/insurance-policy/:id' index element={<Suspense fallback={<></>}><SuccessPage /></Suspense>} />
          <Route path='/insurance-policy-submitted/:id' index element={<Suspense fallback={<></>}><SuccessPage /></Suspense>} />

          <Route path='*' element={<Suspense fallback={<></>}><NoPage /></Suspense>} />
          <Route path='404page' element={<Suspense fallback={<></>}><NoPage /></Suspense>} />


          {/* <Route path='/darktheme-direct-response/:_id/*' index element={<Landingnew />} /> */}
          <Route path='/darktheme-direct-response/:_id/*' index element={<Suspense fallback={<></>}><Landingnew /></Suspense>} />
          <Route path='/terms-and-condition' index element={<Suspense fallback={<></>}><Terms /></Suspense>} />
          <Route path='/privacy-policy' index element={<Suspense fallback={<></>}><Privacy /></Suspense>} />




        </Routes>
      </BrowserRouter>

      <Snackbar
        autoHideDuration={3000}
        open={snakbaropen}
        message={snackBarMsg}
        key="bottomcenter"
        className='successSnackbar'
      />
    </React.Fragment>
  );
}

export default App;
