import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const cancelEvent = createAsyncThunk("cancel-event", async (requestBody) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(requestBody),
    }
    console.log("request body", requestBody)
    const res = await fetch(process.env.REACT_APP_BOOKEVENT + "pece-calendar-event/cancel-event", reqBody);
    const respData = await res.json();

    return respData;
});
export const fetchEventWithIdentifier = createAsyncThunk("fetch-event-identifier", async (requestBody) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(requestBody)
    }
    const res = await fetch(process.env.REACT_APP_API_URL + "api9/fetch-event-identifier", reqBody);
    const respData = await res.json();
    return respData
})

const slotCancelReducer = createSlice({
    name: 'slotcancel',
    initialState: {
        loading: false,
        success: false,
        successMsg: '',
        errorMsg: '',
        error: false,
        fetchIdentifierData: {},
        rescheduleData: '',
        rescheduleMsg: '',
        rescheduleSubmissionState: 0,
        cancelSubmissionState: 0,
    },
    reducers: {
        cancelSubmissionStateReset(state, action) {
            state.success = false;
            state.successMsg = '';
            state.cancelSubmissionState = 0;
        }
    },
    extraReducers: {
        [cancelEvent.pending]: (state, action) => {
            state.loading = true;
            state.cancelSubmissionState = 1;
        },
        [cancelEvent.fulfilled]: (state, action) => {
            console.log(action)
            if (action.payload.status === "Event Cancelled") {
                state.loading = false;
                state.success = true;
                state.successMsg = action.payload.status;
                state.cancelSubmissionState = 2;
            }
            if (action.payload.status === "Failed to Cancel event") {
                state.loading = false;
                state.success = true;
                state.successMsg = action.payload.status;
            }
        },
        [cancelEvent.rejected]: (state, action) => {
            state.loading = false;
            state.error = true;
            state.errorMsg = action.error.message;
            state.cancelSubmissionState = 3;
        },
        [fetchEventWithIdentifier.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchEventWithIdentifier.fulfilled]: (state, action) => {
            console.log(action)
            if (action.payload.status === 'success') {
                state.loading = false;
                state.success = true;
                state.fetchIdentifierData = action.payload.res;
            }
        },
        [fetchEventWithIdentifier.rejected]: (state, action) => {
            console.log(action)
            state.loading = false;
            state.error = action.payload
        }
    }
});
export const { cancelSubmissionStateReset } = slotCancelReducer.actions;
export default slotCancelReducer.reducer;