import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createAsyncThunkHelper } from "../helper/helperFunctions";
import { cloneDeep } from "lodash";
// import store from "../store";

////////////////////////////////// Add Data into DB //////////////////////////////////////////////
export const addDataIntoDB = createAsyncThunk("form/addToDB", createAsyncThunkHelper)

////////////////////////////////// Fetch Auto Complete List //////////////////////////////////////
export const fetchAutoCompleteList = createAsyncThunk("form/fetchAutoCompleteList", createAsyncThunkHelper)

/////////////////////////////// Upload Image /////////////////////////////////////////////////////
export const uploadImage = createAsyncThunk("form/fileuploader", async (req) => {
  console.log("req file uploader======>", req)
})




const formSlice = createSlice({
  name: "form",
  initialState: {
    formFieldsData: {},
    formData: {},
    formError: {},
    autoCompleteList: {},
    loading: {},
    formSubmissionState: {},
    editorData: {},
    formResetFlag: {},
    tempFormUpdateData: {},
    tempFormFieldError: {},
    message: {},
    status: {},
    customButtonClicked: {},
    externalButtonClicked: {},
    autoCompleteOtherFLag: {},
    selectOtherFlag: {},
    otherData: {},
    externalSeachReset: {},
    externalbuttondata: {},
    formCustomButtonClicked: {},
    formExternalInputData: {},
    autoCompleteListState: {},
    autoCompleteLoading: {},
    listnerEditFormData: {},
    fieldInputData: {},
    fieldRemoveData: {},
    autocompleteQuary: {},
    submitBtnDisabled: false

  },
  reducers: {
    setsubmitBtnDisabled(state, action) {
      if (action.payload.flag) state.submitBtnDisabled = true
      if (!action.payload.flag) state.submitBtnDisabled = false;

    },
    resetMessageAndStatus(state, action) {
      if (state.status[action.payload.formId]) delete state.status[action.payload.formId]
      if (state.message[action.payload.formId]) delete state.message[action.payload.formId]
    },
    resetField(state, action) {
      if (state.fieldInputData[action.payload.formId]) delete state.fieldInputData[action.payload.formId]
      if (state.fieldRemoveData[action.payload.formId]) delete state.fieldRemoveData[action.payload.formId]
    },
    setField(state, action) {
      if (!state.fieldInputData[action.payload.formId]) state.fieldInputData[action.payload.formId] = {}
      if (action.payload.after) state.fieldInputData[action.payload.formId]['after'] = action.payload.after
      if (action.payload.before) state.fieldInputData[action.payload.formId]['before'] = action.payload.before
      // state.fieldInputData[action.payload.formId]['index'] = action.payload.index
      state.fieldInputData[action.payload.formId]['fieldData'] = action.payload.fieldData
    },
    removeField(state, action) {
      if (!state.fieldRemoveData[action.payload.formId]) state.fieldRemoveData[action.payload.formId] = {}
      if (action.payload.name) state.fieldRemoveData[action.payload.formId]['name'] = action.payload.name
      // if (action.payload.before) state.fieldRemoveData[action.payload.formId]['before'] = action.payload.before
      // state.fieldRemoveData[action.payload.formId]['index'] = action.payload.index
    },
    // && state.formData[action.payload.fieldName]!=null && state.formData[action.payload.fieldName]!== undefined
    resetFetchAutoCompleteListState(state, action) {
      if (
        state.autoCompleteListState[action.payload.formId] &&
        state.autoCompleteListState[action.payload.formId][action.payload.autoCompleteId]
      )
        state.autoCompleteListState[action.payload.formId][action.payload.autoCompleteId] = 0;

    },
    setListnerFormData(state, action) {
      if (!state.listnerEditFormData[action.payload.formId]) state.listnerEditFormData[action.payload.formId] = {}
      state.listnerEditFormData[action.payload.formId][action.payload.buttonId] = [action.payload.data, action.payload.index]
    },
    resetListnerFormData(state, action) {
      if (state.listnerEditFormData[action.payload.formId][action.payload.buttonId]) delete state.listnerEditFormData[action.payload.formId][action.payload.buttonId]
    },
    setExternalFormData(state, action) {
      console.log("action.payload.index=====================>", action.payload.index)

      if (!state.formExternalInputData[action.payload.formId]) state.formExternalInputData[action.payload.formId] = {}
      if (!state.formExternalInputData[action.payload.formId][action.payload.fieldName]) state.formExternalInputData[action.payload.formId][action.payload.fieldName] = []
      if (typeof (action.payload.index) === "number") state.formExternalInputData[action.payload.formId][action.payload.fieldName][action.payload.index] = action.payload.formData
      else {
        if (action.payload.multiple) state.formExternalInputData[action.payload.formId][action.payload.fieldName].push(action.payload.formData)
        else state.formExternalInputData[action.payload.formId][action.payload.fieldName][0] = action.payload.formData
      }
    },
    resetExternalFormData(state, action) {
      if (state.formExternalInputData[action.payload.formId] && state.formExternalInputData[action.payload.formId][action.payload.fieldName]) delete state.formExternalInputData[action.payload.formId][action.payload.fieldName]
    },
    removeExternalFormData(state, action) {
      if (
        state.formExternalInputData[action.payload.formId] &&
        state.formExternalInputData[action.payload.formId][action.payload.fieldName] &&
        Array.isArray(state.formExternalInputData[action.payload.formId][action.payload.fieldName]) &&
        state.formExternalInputData[action.payload.formId][action.payload.fieldName][action.payload.index]
      ) {
        state.formExternalInputData[action.payload.formId][action.payload.fieldName].splice(action.payload.index, 1)
        // const indexOfElement = state.formExternalInputData[action.payload.formId][action.payload.fieldName].findIndex((data) => data.name === action.payload.fieldData.name)
        // if (indexOfElement !== -1) state.formExternalInputData[action.payload.formId][action.payload.fieldName].splice(indexOfElement, 1)
      }
    },
    setFormCustomButtonClicked(state, action) {
      if (!state.formCustomButtonClicked[action.payload.formId]) state.formCustomButtonClicked[action.payload.formId] = {}
      state.formCustomButtonClicked[action.payload.formId][action.payload.fieldName] = true
    },
    resetFormCustomButtonClicked(state, action) {
      state.formCustomButtonClicked[action.payload.formId][action.payload.fieldName] = false
    },
    setAutocompleteQuary(state, action) {
      if (!state.autocompleteQuary[action.payload.formId]) state.autocompleteQuary[action.payload.formId] = {}
      state.autocompleteQuary[action.payload.formId][action.payload.fieldName] = action.payload?.value
    },
    setAutocompleteQuaryClear(state, action) {
      state.autocompleteQuary[action.payload.formId][action.payload.fieldName] = null
    },


    setFieldNewValue(state, action) {
      console.log("action", state);
      if (state.formData[action.payload.formId]) {
        console.log("hit in reducer");
        state.formData[action.payload.formId][action.payload.fieldName] = action.payload.value;
        console.log("hit in reducer 2", state.formData[action.payload.formId]);

      }
    },

    setOtherData(state, action) {
      if (!state.otherData[action.payload.formId]) state.otherData[action.payload.formId] = {};
      state.otherData[action.payload.formId][action.payload.fieldName] = action.payload.val;
    },
    setexternalbuttondata(state, action) {
      console.log("action in external", action.payload);
      state.externalbuttondata = action.payload
    },
    resetexternalbuttondata(state, action) {
      state.externalbuttondata = {}
    },
    resetOtherData(state, action) {
      if (state.otherData[action.payload.formId] && state.otherData[action.payload.formId][action.payload.fieldName]) delete state.otherData[action.payload.formId][action.payload.fieldName]
    },
    setOtherSelectFlag(state, action) {
      console.log('action.payload', action.payload)
      if (!state.selectOtherFlag[action.payload.formId]) state.selectOtherFlag[action.payload.formId] = {}
      state.selectOtherFlag[action.payload.formId][action.payload.fieldName] = true
    },
    resetOtherSelectFlag(state, action) {
      if (state.selectOtherFlag[action.payload.formId] && state.selectOtherFlag[action.payload.formId][action.payload.fieldName]) state.selectOtherFlag[action.payload.formId][action.payload.fieldName] = false
    },
    setOtherAutocompleteFlag(state, action) {
      console.log('action.payload', action.payload)
      if (!state.autoCompleteOtherFLag[action.payload.formId]) state.autoCompleteOtherFLag[action.payload.formId] = {}
      state.autoCompleteOtherFLag[action.payload.formId][action.payload.fieldName] = true
    },
    resetOtherAutocompleteFlag(state, action) {
      if (state.autoCompleteOtherFLag[action.payload.formId] && state.autoCompleteOtherFLag[action.payload.formId][action.payload.fieldName]) state.autoCompleteOtherFLag[action.payload.formId][action.payload.fieldName] = false
    },
    setCustomButtonCLicked(state, action) {

      if (!state.customButtonClicked[action.payload.formId]) state.customButtonClicked[action.payload.formId] = {};
      state.customButtonClicked[action.payload.formId][action.payload.btnId] = true;
    },
    resetCustomButtonClicked(state, action) {
      if (state.customButtonClicked[action.payload.formId][action.payload.btnId]) state.customButtonClicked[action.payload.formId][action.payload.btnId] = false;
    },

    setExternalButtonClick(state, action) {
      console.log("action897", action);
      if (!state.externalButtonClicked[action.payload.formId]) state.externalButtonClicked[action.payload.formId] = {};
      state.externalButtonClicked[action.payload.formId][action.payload.name] = true;
    },
    resetExternalButtonClick(state, action) {
      if (state.externalButtonClicked[action.payload.formId][action.payload.name]) state.externalButtonClicked[action.payload.formId][action.payload.name] = false;
    },


    setexternalSeachReset(state, action) {
      console.log("action897", action);
      if (!state.externalSeachReset[action.payload.formId]) state.externalSeachReset[action.payload.formId] = {};
      state.externalSeachReset[action.payload.formId][action.payload.name] = true;
    },
    resetexternalSeachReset(state, action) {
      if (state.externalSeachReset[action.payload.formId][action.payload.name]) state.externalSeachReset[action.payload.formId][action.payload.name] = false;
    },

    setFormFieldError(state, action) {
      console.log("action====>", action.payload)
      if (state.tempFormFieldError[action.payload.formId] === null || state.tempFormFieldError[action.payload.formId] === undefined) {
        state.tempFormFieldError[action.payload.formId] = {}
      }
      state.tempFormFieldError[action.payload.formId][action.payload.fieldName] = action.payload.error
    },
    resetFormFieldError(state, action) {
      if (state.tempFormFieldError[action.payload.formId][action.payload.fieldName]) delete state.tempFormFieldError[action.payload.formId][action.payload.fieldName]
    },
    setFormSubmissionStateWithoutEndpoint(state, action) {
      state.formSubmissionState[action.payload] = 4;
    },
    resetTempFormUpdateData(state, action) {
      if (state.tempFormUpdateData[action.payload.formId] && state.tempFormUpdateData[action.payload.formId][action.payload.fieldName]) delete state.tempFormUpdateData[action.payload.formId][action.payload.fieldName]
    },
    setTempFormUpdateData(state, action) {
      console.log("action====>", action.payload)
      if (state.tempFormUpdateData[action.payload.formId] === null || state.tempFormUpdateData[action.payload.formId] === undefined) {
        state.tempFormUpdateData[action.payload.formId] = {}
      }
      if (state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] === null || state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] !== undefined) {
        state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] = "";
      }
      state.tempFormUpdateData[action.payload.formId][action.payload.fieldName] = action.payload.value
    },
    setFormSubmissionStateStore(state, action) {
      console.log(action);
      state.formSubmissionState[action.payload] = 0;
    },
    setEditorData(state, action) {
      if (state.editorData[action.payload.formId] === undefined) {
        state.editorData[action.payload.formId] = {};
      }
      state.editorData[action.payload.formId][action.payload.fieldId] = action.payload.tempVar;
    },
    updateFormFieldsData(state, action) {
      console.log(action.payload);
      const temp = state.formFieldsData[action.payload.formId].fields.find(cur => cur.id === action.payload.fielddata.id);
      console.log(temp);
      if (temp) {
        temp.defaultValue = "<p>TEST</p>"
      }
      if (temp && temp.defaultValue !== action.payload.tempVar) {
        temp.defaultValue = action.payload.tempVar;
      }
    },
    setFormFieldsData(state, action) {
      console.log(action.payload);
      const fieldsData = cloneDeep(action.payload)?.fields.map((eachField) => Object.fromEntries(Object.entries(eachField).filter((cur) => typeof (cur[1]) !== 'function')))
      state.formFieldsData[action.payload.id] = fieldsData;
    },
    setFormData(state, action) {
      // Defining new field against formId in error object if undefined
      if (state.formError[action.payload.formId] === undefined) {
        state.formError[action.payload.formId] = {};
      }


      //////////////////////////////////// Check if last name is valid //////////////////////////
      // if (action.payload.formId === 'userForm' && action.payload.lastname !== "Das") {
      //   // Assigning error msg against field name if undefinded 
      //   if (state.formError[action.payload.formId].lastname === undefined) {
      //     state.formError[action.payload.formId].lastname = 'Lastname is not valid';
      //   }
      // } else {
      //   // Deleting error field and msg against id
      //   delete state.formError[action.payload.formId].lastname;
      // }

      //////////////////////////////////// Setting Formdata into the state //////////////////////
      // Putting data inside the formData aginst formId
      state.formData[action.payload.formId] = action.payload;
    },
    resetForm(state, action) {
      state.formResetFlag[action.payload.formId] = true;
    },
    resetFormResetFlag(state, action) {
      state.formResetFlag[action.payload.formId] = false;
    }
  },
  extraReducers: {
    //////////////////////////// Fetch Auto Complete List /////////////////////////////////////
    [fetchAutoCompleteList.pending]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = true;
      state.autoCompleteLoading[action.meta.arg.restResponseAttachment.formId] = true;
      if (!state.autoCompleteListState[action.meta.arg.restResponseAttachment.formId]) state.autoCompleteListState[action.meta.arg.restResponseAttachment.formId] = {}
      state.autoCompleteListState[action.meta.arg.restResponseAttachment.formId][action.meta.arg.restResponseAttachment.autoCompleteId] = 1;
    },
    [fetchAutoCompleteList.fulfilled]: (state, action) => {
      state.loading[action.payload.autoCompleteId] = false;
      state.autoCompleteLoading[action.meta.arg.restResponseAttachment.formId] = false;
      state.autoCompleteListState[action.meta.arg.restResponseAttachment.formId][action.meta.arg.restResponseAttachment.autoCompleteId] = 2;

      console.log(action.payload);
      const initialResult = [];
      let result;
      if (action.meta.arg.extractionMethod) {
        result = action.payload.reduce(action.meta.arg.extractionMethod, initialResult)
      }
      else {
        result = action.payload.results.res.reduce((acc, eachResult) => {
          acc.push({ key: eachResult.key, label: eachResult.label });
          return acc;
        }, initialResult);
      }
      if (state.autoCompleteList[action.meta.arg.restResponseAttachment.formId] === undefined) {
        state.autoCompleteList[action.meta.arg.restResponseAttachment.formId] = {}
      }
      state.autoCompleteList[action.meta.arg.restResponseAttachment.formId][action.payload.autoCompleteId] = result;
    },
    [fetchAutoCompleteList.rejected]: (state, action) => {
      state.loading[action.meta.arg.restResponseAttachment.autoCompleteId] = false;
      state.autoCompleteLoading[action.meta.arg.restResponseAttachment.formId] = false;
      state.autoCompleteListState[action.meta.arg.restResponseAttachment.formId][action.meta.arg.restResponseAttachment.autoCompleteId] = 3;

    },
    ///////////////////////////// Add Data into DB ////////////////////////////
    [addDataIntoDB.pending]: (state, action) => {
      console.log(action);
      state.formSubmissionState[action.meta.arg.reqBody.formId] = 1;
    },
    [addDataIntoDB.fulfilled]: (state, action) => {
      state.status[action.meta.arg.reqBody.formId] = action.payload?.status ? action.payload.status : ""
      if (action.payload.status === "success" || action.meta.arg.restResponseAttachment?.statusCode == 200) {
        state.formSubmissionState[action.meta.arg.reqBody.formId] = 2;
        state.message[action.meta.arg.reqBody.formId] = ((action.payload.message !== null && action.payload.message !== "" && action.payload.message !== undefined) ? action.payload.message : "Successfully Submitted");
      }
      else {
        state.formSubmissionState[action.meta.arg.reqBody.formId] = 3;
        state.message[action.meta.arg.reqBody.formId] = ((action.payload.message !== null && action.payload.message !== "" && action.payload.message !== undefined) ? action.payload.message : "Something Went Wrong");
      }
    },
    [addDataIntoDB.rejected]: (state, action) => {
      console.log(action);
      state.formSubmissionState[action.meta.arg.reqBody.formId] = 3;
    },
  },
});

export const { resetMessageAndStatus, resetField, setField, removeField, resetExternalFormData, resetListnerFormData, setListnerFormData, resetFetchAutoCompleteListState, removeExternalFormData, setExternalFormData, setFormCustomButtonClicked, resetFormCustomButtonClicked, setOtherData, resetOtherData, setOtherSelectFlag, resetOtherSelectFlag, setOtherAutocompleteFlag, resetOtherAutocompleteFlag, setCustomButtonCLicked, resetCustomButtonClicked, setFormFieldError, resetFormFieldError, setFormSubmissionStateWithoutEndpoint, resetTempFormUpdateData, setTempFormUpdateData, setFormData, resetForm, resetFormResetFlag, setFormFieldsData, updateFormFieldsData, setEditorData, setFormSubmissionStateStore, setExternalButtonClick, resetExternalButtonClick, setexternalSeachReset, resetexternalSeachReset, setexternalbuttondata, resetexternalbuttondata, setFieldNewValue, setAutocompleteQuary, setAutocompleteQuaryClear,setsubmitBtnDisabled } = formSlice.actions;

export default formSlice.reducer;
