import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const formDataSubmitGoogleEvent = createAsyncThunk("signupform/googleevent", async(body) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body),
    }
    const res = await fetch(process.env.REACT_APP_API_URL + "api9/form-submit", reqBody);
    const respData = await res.json();

    return respData;
});
export const formDataSubmitPayment = createAsyncThunk("payment/paymentforgift", async(body) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body),
    }
    const res = await fetch(process.env.REACT_APP_API_URL + "api5/paymentforgift", reqBody);
    const respData = await res.json();

    return respData;
});


const formSubmitSlice = createSlice({
    name: "formSubmit",
    initialState: {
        loading: false,
        formSubmisionState: 0,
        submissionSucessData: {},
        healthExamSignupData: null,
        diabaticPaymentData: null,
        diabaticPaymentNewPatientData: null,
        diabaticPaymentDone: false
    },
    reducers: {
        setFormSubmitStateReset(state, action) {
            state.loading = false;
            state.formSubmisionState = 0;
            state.submissionSucessData = {};
            state.healthExamSignupData = null;
        },
        setInitialDataFromCookie(state, action) {
            state.submissionSucessData = action.payload.formData
        }
    },
    extraReducers: {
        /////////////////////////////////// Fetch Table List ///////////////////////////
        [formDataSubmitGoogleEvent.pending]: (state, action) => {
            state.loading = true;
            state.formSubmisionState = 1
        },
        [formDataSubmitGoogleEvent.fulfilled]: (state, action) => {
            state.loading = false;
            console.log("DataSumittion Sucessfull", action)
            if (action.payload.status === "success") {
                state.formSubmisionState = 2
                state.healthExamSignupData = action.payload.res
                state.submissionSucessData = action.payload.res
                console.log("DataSumittion Sucessfull")
            }
        },
        [formDataSubmitGoogleEvent.rejected]: (state, action) => {
            state.loading = false;
            state.formSubmisionState = 3
        },
        [formDataSubmitPayment.pending]: (state, action) => {
            state.loading = true;
            state.formSubmisionState = 1
        },
        [formDataSubmitPayment.fulfilled]: (state, action) => {
            state.loading = false;
            console.log("DataSumittion Sucessfull", action)
            if (action.payload.status === "success") {
                state.formSubmisionState = 2;
                state.diabaticPaymentData = action.payload.res;
                state.diabaticPaymentDone = true;
                if (action.payload.patient) {
                    state.diabaticPaymentNewPatientData = action.payload.patient;
                }
            }
        },
        [formDataSubmitPayment.rejected]: (state, action) => {
            console.log("DataSumittion Sucessfull formSubmisionState", action)
            if (action.payload.status == "error") {
                console.log("DataSumittion Sucessfull formSubmisionState", action)
                state.loading = false;
                state.formSubmisionState = 3
            }
        }
    }
});


export default formSubmitSlice.reducer;
export const { setInitialDataFromCookie, setFormSubmitStateReset } = formSubmitSlice.actions;