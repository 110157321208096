import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchTimeSolts = createAsyncThunk("fetchtimeslots", async(body) => {
    const reqBody = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(body),
    }
    console.log("request body", body)
        // const res = await fetch(process.env.REACT_APP_API_URL + "api9/fetcheventdetails", reqBody);
    const res = await fetch(process.env.REACT_APP_API_URL + "asyncapi/fetcheventdetails", reqBody);
    const respData = await res.json();

    return respData;
});


export const fiterTimeSolts = createAsyncThunk("calendar/availability", async(reqBody) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
    };
    console.log("process.env==>", process.env);
    const response = await fetch(
        // process.env.REACT_APP_API_URL + "api9/fetcheventdetails",
        process.env.REACT_APP_API_URL + "asyncapi/fetcheventdetails",
        requestOptions
    );
    const respdata = await response.json();

    console.log("respdata after endpoint fetch==>", respdata);

    return respdata;
});
export const fetchTimeSoltsLoadMore = createAsyncThunk("calendar/availabilityloadmore", async(reqBody) => {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
    };
    console.log("process.env==>", process.env);
    const response = await fetch(
        // process.env.REACT_APP_API_URL + "api9/fetcheventdetails",
        process.env.REACT_APP_API_URL + "asyncapi/fetcheventdetails",
        requestOptions
    );
    const respdata = await response.json();

    console.log("respdata after endpoint fetch==>", respdata);

    return respdata;
});


export const booknowevents = createAsyncThunk("calendar/booknowevents", async(reqBody) => {


    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
    };
    console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_BOOKEVENT + "pece-calendar-event/add-calendar",
        requestOptions
    );
    const respdata = await response.json();

    console.log("respdata after endpoint fetch==>", respdata);

    return respdata;
});
export const rescheduleevents = createAsyncThunk("calendar/rescheduleevent", async(reqBody) => {


    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(reqBody),
    };
    console.log("process.env==>", process.env);
    const response = await fetch(
        process.env.REACT_APP_BOOKEVENT + "pece-calendar-event/re-schedule-event",
        requestOptions
    );
    const respdata = await response.json();

    console.log("respdata after endpoint fetch==>", respdata);

    return respdata;
});



const appointmentBookingReducer = createSlice({
    name: "appointment-booking",
    initialState: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
        successMsg: null,
        timeSlots: [],
        patient_details: {},
        practiceInfo: {},
        landing_theme: '',
        calenderEventsData: [],
        booknowData: '',
        rescheduleData: '',
        booknowMsg: '',
        rescheduleMsg: '',
        bookNowSubmissionState: 0,
        fetchIdentifierData: '',
        rescheduleSubmissionState: 0

    },
    reducers: {
        setBooknowSubmissionStateReset(state, action) {
            state.success = false;
            state.error = false;
            state.successmsg = null;
            state.booknowMsg = '';
            state.bookNowSubmissionState = 0;
        },
        setrescheduleSubmissionStateReset(state, action) {
            state.success = false;
            state.successmsg = null;
            state.rescheduleMsg = '';
            state.rescheduleSubmissionState = 0;
        }
    },
    extraReducers: {
        [fetchTimeSolts.pending]: (state, action) => {
            state.loading = true
        },
        [fetchTimeSolts.fulfilled]: (state, action) => {
            console.log("fetchTimeSolts fulfilled action", action)
            if (action.payload.status === 'success') {
                state.loading = false;
                state.timeSlots = action.payload.res;
                state.patient_details = action.payload.patientData ? action.payload.patientData : '';
                state.practiceInfo = action.payload.patientData && action.payload.patientData.praticeInfo ? action.payload.patientData.praticeInfo : '';
                state.landing_theme = action.payload.patientData && action.payload.patientData.landing_theme && action.payload.patientData.landing_theme.campaign_landingpage ? action.payload.patientData.landing_theme.campaign_landingpage : 'darktheme';
                state.success = true;
                state.successMsg = action.payload.status;
                console.log("fetchTimeSolts fulfilled state", state)
            } else if (action.payload.status === "error" && action.payload.res === "Tech not added!") {
                state.loading = false;
                state.error = action.payload.status;
                state.errorMessage = action.payload.res;
            }
        },
        [fetchTimeSolts.rejected]: (state, action) => {
            console.log("fetchTimeSolts rejected action", action)
            state.loading = false;
            state.error = action.payload;
        },
        [fiterTimeSolts.pending]: (state, action) => {
            state.loading = true;
        },
        [fiterTimeSolts.rejected]: (state, action) => {
            state.loading = false;
        },
        [fiterTimeSolts.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "error") {
                state.errorMessage = action.payload.message;
            }
            if (action.payload.status === "success") {
                console.log("action.payload.data", action.payload);
                state.timeSlots = [...action.payload.res];
            }
        },
        [fetchTimeSoltsLoadMore.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchTimeSoltsLoadMore.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchTimeSoltsLoadMore.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "error") {
                state.errorMessage = action.payload.message;
            }
            if (action.payload.status === "success") {
                console.log("action.payload.data", action.payload);
                state.timeSlots = [...state.timeSlots, ...action.payload.res];
            }
        },
        [booknowevents.pending]: (state, action) => {
            state.loading = true;
            state.booknowData = "pending";
            state.booknowMsg = "";
            state.bookNowSubmissionState = 1;

        },
        [booknowevents.rejected]: (state, action) => {
            state.loading = false;
            state.booknowData = "rejected";
            state.booknowMsg = "";
            state.bookNowSubmissionState = 3;
        },
        [booknowevents.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "error") {
                state.errorMessage = action.payload.message;
            }
            // if (action.payload.status === "success") {
            console.log("+++++++++++", action.payload);
            state.booknowData = "success";
            state.booknowMsg = action.payload.status;
            if (state.booknowMsg !== "Someone has already occupied the slot") {
                state.bookNowSubmissionState = 2;
            }
        },
        [rescheduleevents.pending]: (state, action) => {
            state.loading = true;
            state.rescheduleData = "pending";
            state.rescheduleMsg = "";
            state.rescheduleSubmissionState = 1;

        },
        [rescheduleevents.rejected]: (state, action) => {
            state.loading = false;
            state.rescheduleData = "rejected";
            state.rescheduleMsg = "";
            state.rescheduleSubmissionState = 3;
        },
        [rescheduleevents.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload.status === "error") {
                state.rescheduleData = "error";
                state.errorMessage = action.payload.message;
            } else if (action.payload.status === "Failed to Re-schedule event") {
                state.rescheduleData = "error";
                state.errorMessage = action.payload.status;
            } else {
                // if (action.payload.status === "success") {
                console.log("+++++++++++", action.payload);
                state.rescheduleData = "success";
                state.rescheduleMsg = action.payload.status;
                state.rescheduleSubmissionState = 2;
            }
        }
    }
})
export const { setBooknowSubmissionStateReset, setrescheduleSubmissionStateReset } = appointmentBookingReducer.actions;
export default appointmentBookingReducer.reducer;