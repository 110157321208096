import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Cookies } from "react-cookie";
const cookies = new Cookies().getAll();




export const getRepdashboarddata = createAsyncThunk("getRepdashboarddatas", async(reqbody) => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(reqbody)
    };
    const response = await fetch(process.env.REACT_APP_API_URL + "api5/reward-partner-dashboard-list", requestOptions);
    const FetchStages = await response.json();

    return FetchStages;
});






const RewardDashboardReducer = createSlice({
    name: 'RewardDashboardReducer',
    initialState: {
        submitting: false,
        loading: false,
        repdashboarddata: [],
        totalCommission: 0

    },
    reducers: {

        clearclearepdashboarddata(state, action) {
            state.repdashboarddata = [];
            state.totalCommission = 0
        },



    },
    extraReducers: {


        // ================ For GET DATA ================ //
        [getRepdashboarddata.rejected]: (state, action) => {
            // state.endpointsuccess[action.type] = false
            state.successAction = false;
        },
        [getRepdashboarddata.pending]: (state, action) => {
            state.loading = true;
        },
        [getRepdashboarddata.fulfilled]: (state, action) => {

            // console.log(state,'state============')
            console.log(action.payload, 'fetchedgetRepdashboarddata action============')

            if (action.payload.status === 'success') {
                state.repdashboarddata = action.payload.results.res;
                state.totalCommission = action.payload.results.total
                state.loading = false;
            }
        },










    }
})







export default RewardDashboardReducer.reducer;
export const { clearclearepdashboarddata } = RewardDashboardReducer.actions;