


import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const intakeFormDataSubmitGoogleEvent = createAsyncThunk("intake/googleevent", async (body) => {
  console.log("payload Body", body)

  const reqBody = {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify(body),

  }
  const res = await fetch(process.env.REACT_APP_API_URL + "api9/form-submit", reqBody);
  const respData = await res.json();

  return respData;
});


const intakeFormSubmitSlice = createSlice({
  name: "formSubmit",
  initialState: {
    loading: false,
    formSubmisionState: 0,
    submissionSucessData: {}
  },
  reducers: {


  },
  extraReducers: {
    /////////////////////////////////// Fetch Table List ///////////////////////////
    [intakeFormDataSubmitGoogleEvent.pending]: (state, action) => {
      state.loading = true;
      state.formSubmisionState = 1
    },
    [intakeFormDataSubmitGoogleEvent.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.status === "success") {
        state.formSubmisionState = 2
        state.submissionSucessData = action.payload.res
        console.log("DataSumittion Sucessfull")
      }
    },
    [intakeFormDataSubmitGoogleEvent.rejected]: (state, action) => {
      state.loading = false;
      state.formSubmisionState = 3


    },
  }
});


export default intakeFormSubmitSlice.reducer;
export const { } = intakeFormSubmitSlice.actions;