import moment, { tz } from 'moment';
import 'moment-timezone';
import { useDispatch } from 'react-redux';
import { setLoader, setResloved } from '../Pages/Layout/layoutReducer';
import store from '../store';
import { startresolve } from './ResolveReducer';
import accesscontroljson from '../assets/json/accesscontrol.json'



/////// Important Access control Function ////////////
export const accesscontroll = (path, coockie) => {
    console.log("accesscontroljson", accesscontroljson, path, coockie);
    if (accesscontroljson[path]) {
        console.log("accesscontroljson[path]", accesscontroljson[path]);
        if (accesscontroljson[path].user_type && accesscontroljson[path].user_type.length > 0) {
            let loginusertype = coockie.userInfo?.type;
            if (loginusertype && accesscontroljson[path].user_type.includes(loginusertype)) {

                return true

            } else {
                return false
            }

        }
    }
    return false
}



// //////////////////////// Create Async Thunk Helper /////////////////////////////
export const createAsyncThunkHelper = async ({
    BASE_URL,
    endPoint,
    reqBody,
    restBodyAttachment,
    restResponseAttachment,
    bodyWrapper,
    endPointType
}) => {
    // console.log("From Craete Async Thunk Helper", BASE_URL, endPoint, reqBody, restBodyAttachment, restResponseAttachment, bodyWrapper, "endPointType==>", endPointType);
    let newReqBody = { ...reqBody };

    if (
        typeof restBodyAttachment === "object" &&
        restBodyAttachment !== null &&
        Object.keys(restBodyAttachment).length > 0
    ) {
        for (let key of Object.keys(restBodyAttachment)) {
            newReqBody[key] = restBodyAttachment[key];
        }
    }

    /////////////////// for Body Wrapper ////////////////////
    if (bodyWrapper !== "" && bodyWrapper) {
        // console.log(newReqBody);
        newReqBody = {
            [bodyWrapper]: newReqBody,
        };
    }
    // console.log("newReqBody---------------->", newReqBody)
    const requestOptions = {
        method: endPointType ? endPointType : "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(newReqBody),
    };

    ////////////////////////////// delete body if GET method ////////////////////////////////
    if (endPointType !== undefined && endPointType === 'GET') {
        delete requestOptions.body;
    }
    const response = await fetch(
        `${BASE_URL}${endPoint !== "" && endPoint !== undefined ? `${endPoint}` : ""
        }`,
        requestOptions
    );
    let result = await response.json();



    /////////////////////////////////////////////////////////////////////////////////////////// Need modification of without object response ////////////////////////////////////////////////////////

    // if (typeof (result) !== 'object') {
    //   result = { count: result }
    // }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    if (
        typeof restResponseAttachment === "object" &&
        restResponseAttachment !== null &&
        Object.keys(restResponseAttachment).length > 0
    ) {
        for (let key of Object.keys(restResponseAttachment)) {
            result[key] = restResponseAttachment[key];
        }
    }

    return result;
};





////////////////////////////////////// Time Converter Helper //////////////////////////////////
export const timeConverter = ({ time = new Date(), format = "dddd MMMM Do YYYY h:mm:ss a z", ago = false, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone } = {}) => {
    if (ago === true) {
        return moment.unix(time / 1000).fromNow();
    } else {
        return moment(time).tz(timezone).format(format);
    }
}




// console.log(timeConverter(1650883133739, "DD/MM/YYYY"));
// console.log(timeConverter(1650883133739))
// timeConverter(1650883133739, 'MMMM Do YYYY, h:mm:ss a');
// timeConverter(1650883133739, "MMM Do YY");
// timeConverter(1650883133739, "MM/DD/YYYY", true);



// moment().format('MMMM Do YYYY, h:mm:ss a'); // May 19th 2022, 5:02:59 pm
// moment().format("MMM Do YY");               // May 19th 22
// moment().format('dddd');                    // Thursday
// moment().format();                          // 2022-05-19T17:02:59+05:30
// moment("20111031", "YYYYMMDD").fromNow();

////////////////////// random Id Generator //////////////////////
export const randomId = () => {
    return Math.random().toString(36).slice(2) + moment().valueOf();
}

/////////////////////////////////// Check if there is already an api call pending ////////////////////////////
let requestCounter = [];
let activeRequest = false;

export const requestSequencer = ({ dispatch, func, args, priority }) => {
    if (requestCounter.length === 0) {
        startInterval();
    }
    if (priority == 1) {
        ///////////////////////////// For Higher Pririty Endpoint Call ////////////////////////////////
        requestCounter.unshift({ dispatch, func, args });
    } else {
        requestCounter.push({ dispatch, func, args });
    }

}


export const routeHelper = async ({
    path,
    func,
    state,
    navigate,
    body = {},
    dispatch,
    reload = false,
}) => {
    console.log("PATH", path, state, typeof state, func, body);
    if (state.length === 0 || reload || typeof state === "object") {
        // await store.dispatch(func(body));
        console.log("Awaited");
        dispatch(setResloved({ path, resolve: true })); // Setting store as resolved page
        dispatch(setLoader())
        navigate(path);

    } else {
        navigate(path);
    }
};



export const resolvefunction = async (navigate, path, func) => {
    console.log("=======>", path, func);
    if (func && func !== undefined) {
        store.dispatch(startresolve({ loader: true }))
        if (Array.isArray(func) && func.length > 0) {

            for (let i in func) {
                var b = await store.dispatch(func[i]);
                console.log("[]", b);
            }

        } else {
            var a = await store.dispatch(func)

        }
        console.log("======> 8888", b, a);
        navigate(path);

        var c = store.dispatch(startresolve({ loader: false, path: path }))
        console.log("======> 9999", c);



    } else {
        navigate(path)
    }
    // store.dispatch(func[0])

}







const handleRequest = ({ requestCounter }) => {
    activeRequest = true;
    const { dispatch, func, args } = requestCounter.shift()
    dispatch(func(args)).then(() => {
        activeRequest = false;
    })
}

const startInterval = () => {
    const interval = setInterval(() => {
        // console.log('Interval running', requestCounter)
        if (requestCounter.length > 0 && activeRequest === false) {
            handleRequest({ requestCounter });
        }
        if (requestCounter.length === 0 && activeRequest === false) {
            clearInterval(interval);
        }
    }, 50)
}

/////////////////////////////// Check if a youtube video is valid ///////////////////////////////
export const isValidYoutubeVideo = async (videoId) => {
    if (!videoId) return 'Please Provide Youtube Video Id'
    return new Promise(async (resolve) => {
        const url = `https://www.googleapis.com/youtube/v3/videos?part=id%2C+snippet&id=${videoId}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

        const response = await fetch(url);

        const res = await response.json()

        if (res.items.length === 0 && res.pageInfo.totalResults === 0) {
            resolve({
                isValid: false,
            })
        } else {
            resolve({
                isValid: true,
                items: res.items,
                pageInfo: res.pageInfo
            })
        }
    })


}

export const getTokenFromStore = () => {
    return store.getState().loginSlice.token ? store.getState().loginSlice.token : '';
}

// <------------------------------For getting event data from elements------------------------------>
export const getEventData = (dataArr, navigate, elemName, eventName, attribute, routeName, endpointBody) => {
    let el = document.querySelectorAll(elemName);
    if (el != null && dataArr.length > 0 && el.length > 0) {
        for (let i = 0; i <= el.length; i++) {
            if (el[i] != null) {
                el[i].addEventListener(eventName, (e) => {
                    let eventData = JSON.parse(e.target.getAttribute(attribute));
                    console.log("img-gal-card elem event Data --------->", eventData);

                    // return eventData;

                    switch (eventData.type) {
                        case "read_more": navigate(`${routeName}${eventData._id}`, { state: endpointBody })
                            break;

                        default: console.log("Event emited from blog-card element");
                            break;
                    }
                });
            }
        }
    }
}

// export const resolveToPage = async (path, resolveFunc, reqbody, dispatch, navigate) => {
//   // const alreadyResolved = store.getState().layoutSlice.resolved[path]
//   try {
//     if (!resolveFunc) {
//       navigate(path);                               // if already resolved, navigating on already resolved page
//       return;
//     }
//     dispatch(setLoader({ loader: true }));          // Spinning Loader
//     if (Array.isArray(resolveFunc) && resolveFunc.length > 1) resolveFunc.forEach(async (func) => await dispatch(func(reqbody))); // for multiple api calls
//     else if (Array.isArray(resolveFunc) && resolveFunc.length == 1) await dispatch(resolveFunc[0](reqbody));  // resolveFunc is the asyncthunk for fetching initial data
//     dispatch(setResloved({ path, resolve: true })); // Setting store as resolved page
//     dispatch(setLoader());                          // Stopping Loader
//     console.log("await-----------after333");
//     navigate(path);                                 // Navigating to the path
//   } catch (error) { }
// };

export const inputExternalPlugin = (page, src) => {

    const getExternalID = document.getElementById("externalID") ? document.getElementById("externalID") : null;
    console.log("getExternalID ", getExternalID)
    if (getExternalID !== null) {
        getExternalID.parentNode.removeChild(getExternalID)
    }
    console.log("getExternalID ", getExternalID)

    const element = document.querySelector("head")
    const script = document.createElement('script')
    script.id = "externalID";
    script.src = src
    script.async = true
    element.append(script);
    // console.log("training script", element)

}