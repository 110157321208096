import React from 'react'
import "../Terms-and-Conditions/terms.css";
import Footer from '../../Layout/Footer';

function Terms() {
  return (
    <>
      <div className='terms_main_wrapper'>
        <div className='common_width'>
          <div class="diabetes_rxbrg_logo">
            <img src="https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/decision_doc_logo.webp" />

          </div>
          <div className='terms_sub_wrapper'>
            <div className='terms_sub_sec_wrapper'>
              <h2>AGREEMENT TO TERMS</h2>
              <p>These Terms of Use create a legally binding contract between you (whether individually or representing an entity) and Decision Doc Rexburg ("Company," "we," "us," or "our") regarding your use of the Slow Growth Academy website and any associated media forms, channels, mobile websites, or applications (collectively, the "Site"). By accessing the Site, you acknowledge that you've read, understood, and agree to abide by these Terms of Use. If you don't agree with any part of these terms, please refrain from using the Site.</p>
              <p>Supplementary terms, conditions, or documents posted on the Site are considered integral parts of this agreement. We reserve the right to modify these Terms of Use at any time without specific notice. Regularly check the "Last updated" date to stay informed about updates. Your continued use of the Site after any changes implies acceptance of the revised terms.</p>
              <p>The information on the Site is not intended for distribution or use where prohibited by law. Users accessing the Site from other locations are responsible for compliance with local laws.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>INTELLECTUAL PROPERTY RIGHTS</h1>
              <p>Unless stated otherwise, the Site, including source code, databases, functionality, software, designs, media content, and trademarks, is our property protected by copyright, trademark, and other intellectual property laws. No part of the Site, content, or trademarks may be used for commercial purposes without our explicit written consent.</p>
              <p>You are granted a limited, non-commercial license to access and use the Site. All rights not expressly granted are reserved by us.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>USER REPRESENTATIONS</h1>
              <p>By using the Site, you represent and warrant that you have legal capacity, agree to comply with these Terms of Use, are of legal age, won't use automated or non-human means to access the Site, won't use it for illegal purposes, and will comply with applicable laws. Providing untrue or incomplete information may result in the suspension or termination of your account.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>FEES AND PAYMENT</h1>
              <p>Accepted payment methods include Visa, MasterCard, Maestro, American Express, JCB, and Discover. Some services may require payment, and you agree to provide accurate and current payment information. We reserve the right to update prices and correct errors. All payments are in USD.</p>
            </div>
            <div className='terms_sub_sec_wrapper' >
              <h1>TERMS OF PURCHASE AND REFUND:</h1>
              <p>The Decision Doc purchase of the Diabetes Report is considered a final purchase with no refunds in most cases. Refunds are at the discretion of Decision Doc Rexburg only. If you feel that a refund should be authorized, you are welcome to email us at <a href='mailto:support@decisiondoc.com'>support@decisiondoc.com</a>. Please include your purchase receipt in the email.</p>
            </div>
            <div className='terms_sub_sec_wrapper' >
              <h1>PROHIBITED ACTIVITIES</h1>
              <p>Users must not misuse the Site, including unauthorized data retrieval, unsolicited advertising, or interference with security features. Prohibited activities also include using the Site for illegal purposes, attempting to bypass security measures, and engaging in harassment.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>USER-GENERATED CONTRIBUTIONS</h1>
              <p>The Site does not support user-submitted content. However, if provided, contributions must comply with these Terms of Use and our Privacy Policy. Users retain ownership of their contributions but grant us the right to use and share feedback without compensation.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>SUBMISSIONS</h1>
              <p>Any submissions (questions, comments, etc.) become our property, and we may use them without acknowledgment or compensation. By submitting, you waive moral rights and warrant originality.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>SITE MANAGEMENT</h1>
              <p>We may monitor the Site for violations, take legal action against violators, and manage the Site to protect our rights.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>TERM AND TERMINATION</h1>
              <p>These Terms of Use remain valid while you use the Site. We can deny access or terminate use for any reason without notice. If terminated, you may not create a new account.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>MODIFICATIONS AND INTERRUPTIONS</h1>
              <p>We can change, modify, or remove Site content without notice. We're not liable for any loss caused by interruptions, errors, or modifications to the Site.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>GOVERNING LAW</h1>
              <p>United States law governs these Terms of Use, and both parties’ consent to the State of Idaho District Court's exclusive jurisdiction.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>DISPUTE RESOLUTION</h1>
              <p>We encourage informal negotiations for 30 days before arbitration. Arbitration will be conducted by the International Commercial Arbitration Court under the European Arbitration Chamber. Exceptions include disputes over intellectual property and claims for injunctive relief.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>CORRECTIONS</h1>
              <p>We may correct errors on the Site, including pricing errors. We're not obligated to update information.
              </p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>DISCLAIMER</h1>
              <p>The Site is provided "as-is." We disclaim all warranties and assume no liability for errors, personal injury, unauthorized access, or interruptions. We're not responsible for third-party products or services.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>LIMITATIONS OF LIABILITY</h1>
              <p>We, our directors, employees, and agents are not liable for any direct, indirect, or consequential damages arising from your use of the Site, even if advised of the possibility. Our liability is limited to the lesser of your payments to us or 12 months' subscription.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>INDEMNIFICATION</h1>
              <p>You agree to indemnify us from any claims, including legal fees, arising from your use of the Site, breach of these Terms of Use, or violation of third-party rights.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>USER DATA</h1>
              <p>While we perform backups, you're responsible for data transmitted to the Site. We're not liable for data loss or corruption.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h1>
              <p>Your use of the Site constitutes electronic communications. You agree to electronic signatures, contracts, orders, and delivery of notices via email or the Site.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>MISCELLANEOUS</h1>
              <p>These Terms of Use, along with posted policies, constitute the entire agreement. Our failure to enforce any provision doesn't waive our rights. You waive defenses based on the electronic form of these terms.</p>
            </div>
            <div className='terms_sub_sec_wrapper'>
              <h1>CONTACT US</h1>
              <p>For complaints or further information, contact us at <a href='mailto:support@decisiondoc.com'>support@decisiondoc.com</a>. Or Valley River Shopping Center, 125 Valley River Drive, Rexburg, ID, suite #3E. Phone: <a href='tel:(208) 757-0245<'>(208) 757-0245</a></p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Terms
