import "../Diabatics/DiabaticsPaymant.css";
import DecisionDocLogo from "../../assets/diabatic_payment_decisiondoc_logo.png";
import CardImage from "../../assets/diabatic_all_cards.png";
import AmeriacanExpress from "../../assets/stripe_american_express_logo.png";
import DinersClub from "../../assets/stripe_diners_club_logo.png";
import Discover from "../../assets/stripe_discover_logo.png";
import JCB from "../../assets/stripe_jcb_logo.png";
import MasterCard from "../../assets/stripe_master_card_logo.png";
import Visa from "../../assets/stripe_visa_logo.png";
import UnionPay from "../../assets/stripe_union_pay_logo.png";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateSharpIcon from "@mui/icons-material/CreateSharp";
import { Form } from "../../form/Form";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import {
  Backdrop,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fade,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  styled,
  tableCellClasses,
} from "@mui/material";

import { Box, style } from "@mui/system";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import {
  formDataSubmitGoogleEvent,
  formDataSubmitPayment,
} from "../SignupFormReducer";
import store from "../../store";
import PaymentComponent from "./CardElement";
import { DataGrid } from "@mui/x-data-grid";
import Footer from "../../Layout/Footer";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements, PaymentElement } from "@stripe/react-stripe-js";

const DiabaticsPayment = (props) => {
  let { patientID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const moment = require("moment");

  const [loading, setloading] = useState(false);
  const [patientSubmitModalLoading, setPatientSubmitModalLoading] =
    useState(false);
  const [patientSubmitModalSubmitClicked, setPatientSubmitModalSubmitClicked] =
    useState(false);
  const [patientSubmitAllData, setPatientSubmitAllData] = useState(false);
  const [snakbaropen, setSnakbarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openPatientListModal, setOpenPatientListModal] = useState(false);
  const [isEditPatient, setIsEditPatient] = useState(false);
  const [isDeletePatient, setIsDeletePatient] = useState(false);

  const [locationArry, setLocationArry] = useState([]);
  const [dropDownData, setDropDownData] = useState([]);
  const [isStateValid, setIsStateValid] = useState(false);
  const [formSubmisionState, setFormSubmisionState] = useState(0);
  const [diabaticPaymentDone, setDiabaticPaymentDone] = useState(false);
  const [diabaticPaymentNewPatientData, setDiabaticPaymentNewPatientData] =
    useState([]);
  const [totalAmount, setTotalAmount] = useState(197);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [allFieldValidate, setAllFieldValidate] = useState(false);
  const [cardError, setCardError] = useState(false);
  const [cardErrorMsg, setCardErrorMsg] = useState(null);

  const [dateTimePickerValue, setDateTimePickerValue] = useState(null);
  const [patientDetails, setPatientDetails] = useState(null);
  const [isGoToTop, setIsGoToTop] = useState(false);
  const [fetchPatientDetailsSuccess, setFetchPatientDetailsSuccess] =
    useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  // const currentTime = new Date().getTime();

  const {
    register,
    //   handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    clearErrors,
    //   formState: { errors },
    //   setError,
    reset,
  } = useForm();
  const diabaticPaymentData = useSelector((state) =>
    state.formSubmitSlice && state.formSubmitSlice.diabaticPaymentData
      ? state.formSubmitSlice.diabaticPaymentData
      : null
  );
  const submissionSucessData = useSelector((state) =>
    state.formSubmitSlice && state.formSubmitSlice.submissionSucessData
      ? state.formSubmitSlice.submissionSucessData
      : null
  );

  const emailErrorMessage = {
    required: "Email ID is Required",
    pattern: "Please Enter Valid Email",
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 20,
    },
    {
      field: "patient_name",
      headerName: "Patient name",
      // width: 70,
      sortable: true,
    },
    {
      field: "patient_email",
      headerName: "Patient Email",
      // width: 130,
      sortable: true,
    },
    // {
    //   field: "gender",
    //   headerName: "Gender",
    //   // width: 130
    // },
    {
      field: "phone_number",
      headerName: "Phone",
      type: "number",
      // width: 90,
      sortable: true,
    },
    // {
    //   field: "state",
    //   headerName: "State",
    //   sortable: true,
    //   // width: 160,
    // },
    // {
    //   field: "city",
    //   headerName: "City",
    //   sortable: true,
    //   // width: 160,
    // },
    // {
    //   field: "zip_code",
    //   headerName: "Zip",
    //   sortable: true,
    //   // width: 160,
    // },
    // {
    //   field: "giftedondatetime",
    //   headerName: "Gift Date",
    //   sortable: true,
    //   // width: 160,
    // },
    {
      field: "action",
      headerName: "Action",
      renderCell: ({ row }) => (
        <>
          <span
            className="modalActionButton editButton"
            onClick={() => editPatient(row)}
          >
            <CreateSharpIcon />
          </span>
          <span
            className="modalActionButton deleteButton"
            onClick={() => deletePatient(row)}
          >
            <DeleteIcon />
          </span>
        </>
      ),
    },
  ];

  const [patientRows, setPatientRows] = useState([]);

  // const rows = [
  // { id: 1, lastName: "Snow", firstName: "Jon", age: 35, key: 1 },
  // { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42, key: 2 },
  // { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45, key: 3 },
  // { id: 4, lastName: "Stark", firstName: "Arya", age: 16, key: 4 },
  // { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null, key: 5 },
  // { id: 6, lastName: "Melisandre", firstName: null, age: 150, key: 6 },
  // { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44, key: 7 },
  // { id: 8, lastName: "Frances", firstName: "Rossini", age: 36, key: 8 },
  // { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65, key: 9 },
  // ];

  const [error, setError] = useState({
    billing_name: "",
    billing_email: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_phone_number: "",
    billing_gender: "",
    address_line_1: "",
    patient_name: "",
    patient_email: "",
    address: "",
    phone_number: "",
    gender: "",
    state: "",
    city: "",
    zip_code: "",
    card_number: "",
    holder_name: "",
    expiry_date: "",
    cvv: "",
    is_gift: false,
    giftedondatetime: null,
    giftedby: "",
    exp_month: "",
    exp_year: "",
    amount: "",
    patient_id: "",
    patient_data: [],
  });
  const [paymentObject, setPaymentObject] = useState({
    billing_name: "",
    billing_email: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_phone_number: "",
    billing_gender: "",
    address_line_1: "",
    patient_name: "",
    patient_email: "",
    address: "",
    phone_number: "",
    gender: "",
    state: "",
    city: "",
    zip_code: "",
    card_number: "",
    holder_name: "",
    expiry_date: "",
    cvv: "",
    is_gift: false,
    giftedondatetime: null,
    giftedby: "",
    exp_month: "",
    exp_year: "",
    amount: "",
    patient_id: "",
    patient_data: [],
    amount_roud: "",
    payment_id: "",
  });
  const [errorPatientData, setErrorPatientData] = useState({
    patient_name: "",
    patient_email: "",
    address: "",
    phone_number: "",
    gender: "",
    state: "",
    city: "",
    zip_code: "",
    giftedondatetime: null,
    id: "",
    practice_id: "",
    link_techs: [],
    uniqueCode: "",
    is_pecemarketing: 1,
    is_pecemarketingsymptom: true,
    paging_step: 1,
    link_by: "",
    link_location: "",
    practice_name: "",
    link_sub_id: [],
    paging_step_one_submission: new Date().getTime(),
    domain: "www.diabetesrexburg.com",
    domain_url: "diabetesrexburg",
    atten_email: [],
  });
  const [paymentObjectPatientData, setPaymentObjectPatientData] = useState({
    patient_name: "",
    patient_email: "",
    address: "",
    phone_number: "",
    gender: "",
    state: "",
    city: "",
    zip_code: "",
    giftedondatetime: null,
    id: "",
    practice_id: "",
    link_techs: [],
    uniqueCode: "",
    is_pecemarketing: 1,
    is_pecemarketingsymptom: true,
    paging_step: 1,
    link_by: "",
    link_location: "",
    practice_name: "",
    link_sub_id: [],
    paging_step_one_submission: new Date().getTime(),
    domain: "www.diabetesrexburg.com",
    domain_url: "diabetesrexburg",
    atten_email: [],
  });

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "card_number") {
      value = cardNumberFormating(value);
    }
    if (name == "expiry_date") {
      value = expiryDateFormating(value);
    }
    if (name == "cvv") {
      if (value.length > 3) {
        return false;
      }
    }
    if (name == "zip_code" || name === "billing_zip") {
      if (value.length > 5) {
        return false;
      }
    }
    if (name == "billing_email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(paymentObject?.billing_email)) {
        setError({
          ...error,
          billing_email: "Please enter a valid email address",
        });
      } else {
        setError({ ...error, billing_email: "" });
      }
    }
    if (name == "patient_email") {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(paymentObject?.patient_email)) {
        if (openConfirmModal && patientSubmitModalSubmitClicked) {
          setErrorPatientData({
            ...errorPatientData,
            patient_email: "Please enter a valid email address",
          });
        } else {
          setError({
            ...error,
            patient_email: "Please enter a valid email address",
          });
        }
      } else {
        console.log("fff");
        if (openConfirmModal) {
          setErrorPatientData({ ...errorPatientData, patient_email: "" });
        } else {
          setError({ ...error, patient_email: "" });
        }
      }
    }
    if (name == "billing_phone_number") {
      if (value.length > 14) {
        return false;
      } else {
        var formatted = formatPhoneText(e.target.value);
        value = formatted;
      }
    }
    if (name == "billing_name") {
      if (value !== "") {
        setError({ ...error, billing_name: "" });
      }
    }
    if (name == "patient_name") {
      if (value !== "") {
        // setError({ ...error, patient_name: "" });
        setErrorPatientData({ ...errorPatientData, patient_name: "" });
      }
    }
    if (name == "phone_number") {
      if (value !== "") {
        // setError({ ...error, phone_number: "" });
        setErrorPatientData({ ...errorPatientData, phone_number: "" });
      }
    }
    if (name == "address") {
      if (value !== "") {
        // setError({ ...error, address: "" });
        setErrorPatientData({ ...errorPatientData, address: "" });
      }
    }
    if (name == "city") {
      if (value !== "") {
        // setError({ ...error, city: "" });
        setErrorPatientData({ ...errorPatientData, city: "" });
      }
    }
    if (name == "zip_code") {
      if (value !== "") {
        // setError({ ...error, zip_code: "" });
        setErrorPatientData({ ...errorPatientData, zip_code: "" });
      }
    }

    if (name === "is_gift") {
      console.log("paymentObject", paymentObject);
      if (paymentObject.is_gift) {
        value = false;
        setPatientSubmitAllData(false);
        setPaymentObject({
          ...paymentObject,
          is_gift: false,
          giftedby: "",
          giftedondatetime: null,
          state: "",
          city: "",
          patient_name: "",
          patient_email: "",
          address: "",
          zip_code: "",
          phone_number: "",
          gender: "",
          patient_data: [],
        });
        setDateTimePickerValue(null);
        setPaymentObjectPatientData({
          ...paymentObjectPatientData,
          giftedondatetime: null,
          state: "",
          city: "",
          patient_name: "",
          patient_email: "",
          address: "",
          zip_code: "",
          phone_number: "",
          gender: "",
          id: "",
          practice_id: "",
          link_techs: [],
          uniqueCode: "",
        });
      } else {
        value = true;
        setOpenConfirmModal(true);
        setPaymentObject({ ...paymentObject, [name]: value });
        if (openConfirmModal) {
          setPaymentObjectPatientData({
            ...paymentObjectPatientData,
            [name]: value,
          });
        }
      }
    } else {
      if (openConfirmModal) {
        setPaymentObjectPatientData({
          ...paymentObjectPatientData,
          [name]: value,
        });
      }
      setPaymentObject({ ...paymentObject, [name]: value });
    }
  };
  const handleChange = (event) => {
    if (event.target.value !== "") {
      if (openConfirmModal) {
        setErrorPatientData({ ...errorPatientData, gender: "" });
      } else {
        setError({ ...error, gender: "" });
      }
    }
    if (openConfirmModal) {
      setPaymentObjectPatientData({
        ...paymentObjectPatientData,
        gender: event.target.value,
      });
    } else {
      setPaymentObject({
        ...paymentObject,
        billing_gender: event.target.value,
      });
    }
    // setValue("gender", event.target.value);
  };
  const genderErrorMessage = {
    required: "Gender is Required",
  };
  const setPhoneNumber = (e) => {
    // console.log("target value",e.target.value)
    if (e.target.value !== "") {
      // setError({ ...error, phone_number: "" });
      if (openConfirmModal) {
        setErrorPatientData({ ...errorPatientData, phone_number: "" });
      }
    }
    var formatted = formatPhoneText(e.target.value);
    // var isError = (validatePhone(formatted) || formatted.length === 0);
    // setValue("phone_number", formatted);
    // setPaymentObject({ ...paymentObject, phone_number: formatted });
    if (openConfirmModal) {
      setPaymentObjectPatientData({
        ...paymentObjectPatientData,
        phone_number: formatted,
      });
    }
  };
  function formatPhoneText(value) {
    value = value.trim().replaceAll("-", "");
    value = value.trim().replaceAll("(", "");
    value = value.trim().replaceAll(")", "");
    value = value.trim().replaceAll(" ", "");

    if (value.length > 3 && value.length <= 6)
      value = "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
    else if (value.length > 6)
      value =
        "(" +
        value.slice(0, 3) +
        ")" +
        " " +
        value.slice(3, 6) +
        "-" +
        value.slice(6);

    return value;
  }
  const phoneNumberErrorMessage = {
    required: "Phone Number is Required",
    // minLength: "Phone Number should be 10 digit",
    maxLength: "Phone Number should not exceed 20 digit",
  };

  const cardNumberFormating = (value) => {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    if (v) {
      var matches = v.match(/\d{4,16}/g);
      var match = (matches && matches[0]) || "";
      var parts = [];
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      if (parts.length) {
        return parts.join(" ");
      } else {
        return value;
      }
    }
  };
  const expiryDateFormating = (value) => {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    if (v) {
      var matches = v.match(/\d{0,4}/g);
      var match = (matches && matches[0]) || "";
      var parts = [];
      if (match.length == 1 && match > 1) {
        parts.push("0" + match);
      } else {
        for (let i = 0, len = match.length; i < len; i += 2) {
          parts.push(match.substring(i, i + 2));
        }
      }
      if (parts.length > 0 && parseInt(parts[0]) > 12) {
        if (document.querySelector(".formInput")) {
          let formInputField = document.querySelector(".expiryDateField");
          formInputField.classList.add("errorField");
        }
        setError({ ...error, expiry_date: "Month Not Valid!" });
        if (parts.length) {
          return parts.join("/");
        } else {
          return value;
        }
      } else {
        if (document.querySelector(".formInput")) {
          let formInputField = document.querySelector(".expiryDateField");
          formInputField.classList.remove("errorField");
        }
        if (parts.length) {
          return parts.join("/");
        } else {
          return value;
        }
      }
      //    }
      //   }
      //   var match = (matches && matches[0]) || "";
      //   var parts = [];
      //   for (let i = 0, len = match.length; i < len; i += 2) {
      //     parts.push(match.substring(i, i + 2));
      //   }
      //   if (parts.length) {
      //     return parts.join("/");
      //   } else {
      //     return value;
      //   }
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
    if (!showPassword) {
      if (document.querySelector(".cvvField")) {
        let cvvField = document.querySelector(".cvvField");
        cvvField.classList.add("cvvTextField");
      }
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const stateDropDown = (e) => {
    if (e.target.value !== "") {
      if (openConfirmModal) {
        setErrorPatientData({ ...errorPatientData, state: "" });
      } else {
        setError({ ...error, state: "" });
      }
    }
    if (paymentObject.is_gift && openConfirmModal) {
      setPaymentObjectPatientData({
        ...paymentObjectPatientData,
        state: e.target.value,
      });
    } else {
      setPaymentObject({ ...paymentObject, billing_state: e.target.value });
    }
  };
  const selectState = (item) => {
    setIsStateValid(true);
    if (paymentObject.is_gift && openConfirmModal) {
      setPaymentObjectPatientData({
        ...paymentObjectPatientData,
        state: item.abbr,
      });
    } else {
      setPaymentObject({ ...paymentObject, billing_state: item.abbr });
    }
  };
  const onFetchState = async (value) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ search_str: value ? value : "" }),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api9/fetch-allstates",
      requestOptions
    );

    console.log("response state here >>>>>", response);
    const resResult = await response.json();
    const data = resResult.sates.map((data) => {
      return { label: data.name, _id: data._id, abbr: data.abbreviation };
    });
    setLocationArry(data);
    setDropDownData(data);
  };

  const modalClose = () => {
    setOpenConfirmModal(false);
    if (
      paymentObject.giftedby === "" &&
      paymentObject.giftedondatetime === ""
    ) {
      setPaymentObject({ ...paymentObject, is_gift: false });
    }
    if (!patientSubmitAllData) {
      setPaymentObject({ ...paymentObject, is_gift: false });
    }
    setIsEditPatient(false);
    // setPaymentObject({
    //   ...paymentObject,
    //   address_line_1: "",
    //   patient_name: "",
    //   patient_email: "",
    //   address: "",
    //   phone_number: "",
    //   gender: "",
    //   state: "",
    //   city: "",
    //   giftedondatetime: "",
    //   zip_code: "",
    // });
    console.log(
      "patientSubmitAllData",
      patientSubmitAllData,
      "paymentObject",
      paymentObject
    );
    setTimeout(() => {
      setSnakbarOpen(false);
      setSnackBarMsg("");
    }, 2000);
  };
  const patientListModalClose = () => {
    setOpenPatientListModal(false);
    setIsEditPatient(false);
    console.log("paymentObject", paymentObject);
  };
  useEffect(() => {
    if (!patientSubmitAllData) {
      setPaymentObject({ ...paymentObject, is_gift: false });
    } else {
      setPaymentObject({ ...paymentObject, is_gift: true });
    }
  }, [patientSubmitAllData]);

  useEffect(() => {
    onFetchState();
    let tempTime = roundToHour(new Date());
    // tempTime = Math.floor(tempTime / 10) * 10

    console.log("tempTime", tempTime);
    // setCurrentTime()
    // currentTime = Math.floor(currentTime / 10) * 10
  }, []);

  const roundToHour = (date) => {
    let p = 60 * 60 * 1000; // milliseconds in an hour
    return date.getMinutes() >= 30 ? date.getHours() + 1 : date.getHours();
  };

  useEffect(() => {
    if (submissionSucessData) {
      setPatientDetails(submissionSucessData);
      setPaymentObject({
        ...paymentObject,
        billing_name: submissionSucessData.patient_name,
        billing_email: submissionSucessData.patient_email,
        address_line_1: submissionSucessData.address,
        billing_gender: submissionSucessData.gender,
        // billing_state: submissionSucessData.state,
        billing_city: submissionSucessData.city,
        billing_phone_number: submissionSucessData.phone_number,
        billing_zip: submissionSucessData.zip,
      });
      if (locationArry && locationArry.length > 0) {
        locationArry.forEach((e) => {
          if (e.label === patientDetails.state) {
            setPaymentObject({
              ...paymentObject,
              billing_state: e.abbr,
            });
          }
        });
      }
    }
    //console.log("paramid", patientID, patientDetails, paymentObject);
  }, [submissionSucessData, locationArry, patientDetails]);

  const agreeComfirmation = () => {
    setPatientSubmitModalLoading(true);
    setPatientSubmitModalSubmitClicked(true);
    let errorObj = {};

    if (paymentObjectPatientData.patient_name == "") {
      errorObj.patient_name = "This Field is Required!";
    }
    if (paymentObjectPatientData.patient_email === "") {
      errorObj.patient_email = "This Field is Required!";
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(paymentObjectPatientData?.patient_email)) {
        errorObj.patient_email = "Please enter a valid email address";
      } else {
        delete errorObj.patient_email;
      }
    }

    if (paymentObjectPatientData.gender == "") {
      errorObj.gender = "This Field is Required!";
    }
    if (paymentObjectPatientData.phone_number == "") {
      errorObj.phone_number = "This Field is Required!";
    }

    if (paymentObjectPatientData.phone_number.length < 14) {
      errorObj.phone_number = "Please enter a valid 10-digit phone number";
    }
    if (paymentObjectPatientData.address == "") {
      errorObj.address = "This Field is Required!";
    }
    if (paymentObjectPatientData.city == "") {
      errorObj.city = "This Field is Required!";
    }
    if (paymentObjectPatientData.state == "") {
      errorObj.state = "This Field is Required!";
    }
    if (paymentObjectPatientData.zip_code == "") {
      errorObj.zip_code = "This Field is Required!";
    }
    if (paymentObjectPatientData.giftedondatetime == "") {
      errorObj.giftedondatetime = "This Field is Required!";
    }
    if (Object.keys(errorObj).length > 0) {
      setErrorPatientData(errorObj);
      if (document.querySelector(".form_field")) {
        let formInputFields = document.querySelectorAll(".formInput");
        if (formInputFields && formInputFields.length > 0) {
          formInputFields.forEach((e, i) => {
            e.classList.add("errorFieldAll");
          });
        }
      }
      setPatientSubmitModalLoading(false);
    }
    if (Object.keys(errorObj).length === 0) {
      if (process.env.REACT_APP_PROJECT_ENV === "dev") {
        paymentObjectPatientData.practice_id = patientDetails.practice_id;
        paymentObjectPatientData.link_by = patientDetails.practice_id;
      }
      if (process.env.REACT_APP_PROJECT_ENV === "production") {
        paymentObjectPatientData.practice_id = patientDetails.practice_id;
        paymentObjectPatientData.link_by = patientDetails.practice_id;
      }
      paymentObjectPatientData.link_techs = patientDetails.link_techs;
      paymentObjectPatientData.uniqueCode = patientDetails.uniqueCode;
      paymentObjectPatientData.is_pecemarketing =
        patientDetails.is_pecemarketing;
      paymentObjectPatientData.is_pecemarketingsymptom =
        patientDetails.is_pecemarketingsymptom;
      paymentObjectPatientData.link_location = patientDetails.link_location;
      paymentObjectPatientData.practice_name = patientDetails.practice_name;
      paymentObjectPatientData.link_sub_id = patientDetails.link_sub_id;
      paymentObjectPatientData.paging_step_one_submission =
        new Date().getTime();
      paymentObjectPatientData.paging_step = 1;
      paymentObjectPatientData.atten_email = [
        {
          name: paymentObjectPatientData.patient_name,
          email: paymentObjectPatientData.patient_email,
          phone_number: paymentObjectPatientData.phone_number,
        },
      ];

      paymentObjectPatientData.patient_email =
        paymentObjectPatientData.patient_email.toLowerCase().trim();
      // console.log(patientDetails,"<<<<<<<<patientDetails")
      // if (patientDetails.link_techs && patientDetails.link_techs.length > 0) {
      //   patientDetails.link_techs.forEach((f) => {
      //     paymentObjectPatientData.link_techs = [{ _id: f._id }];
      //   });
      // }

      setTimeout(() => {
        if (isEditPatient) {
          paymentObject.patient_data.forEach((e, i) => {
            if (paymentObjectPatientData.id === e.id) {
              // paymentObject.patient_data[i] = paymentObjectPatientData;
              e.patient_name = paymentObjectPatientData.patient_name;
              e.patient_email = paymentObjectPatientData.patient_email;
              e.address = paymentObjectPatientData.address;
              e.phone_number = paymentObjectPatientData.phone_number;
              e.gender = paymentObjectPatientData.gender;
              e.state = paymentObjectPatientData.state;
              e.city = paymentObjectPatientData.city;
              e.zip_code = paymentObjectPatientData.zip_code;
              e.giftedondatetime = paymentObjectPatientData.giftedondatetime;
              e.id = paymentObjectPatientData.id;
            }
          });
        } else {
          if (paymentObject.patient_data.length > 0) {
            let tempId = paymentObject.patient_data.length + 1;
            paymentObjectPatientData.id = tempId;
            Object.preventExtensions(paymentObjectPatientData);
            let amoutTemp = 197 * (paymentObject.patient_data.length + 1);
            setTotalAmount(amoutTemp);
            paymentObject.patient_data = [
              ...paymentObject.patient_data,
              paymentObjectPatientData,
            ];
            // setTimeout(() => {
            //   paymentObject.patient_data.push(paymentObjectPatientData);
            // }, 100);
          } else {
            paymentObjectPatientData.id = 1;
            paymentObject.patient_data.push(paymentObjectPatientData);
          }
        }
        console.log(
          "paymentObjectPatientData---",
          paymentObjectPatientData,
          "paymentObject",
          paymentObject,
          "isEditPatient",
          isEditPatient
        );
        setSnakbarOpen(true);
        if (isEditPatient) {
          setSnackBarMsg("Recipient Updated Successfully");
        } else {
          setSnackBarMsg("Recipient Added Successfully");
        }
        setPatientSubmitModalLoading(false);
        setPatientSubmitAllData(true);
        modalClose();
      }, 2000);
    }
  };

  const editGiftDetail = () => {
    if (paymentObject.patient_data.length > 1) {
      setOpenPatientListModal(true);
      let allPatients = paymentObject.patient_data;
      allPatients.forEach((e, i) => {
        e.id = i + 1;
      });
      setPatientRows(allPatients);
    } else {
      editPatient(paymentObject.patient_data[0]);
    }
    setIsEditPatient(true);
  };
  const editPatient = (item) => {
    setPaymentObjectPatientData({
      patient_name: item.patient_name,
      patient_email: item.patient_email,
      address: item.address,
      phone_number: item.phone_number,
      gender: item.gender,
      state: item.state,
      city: item.city,
      zip_code: item.zip_code,
      giftedondatetime: item.giftedondatetime,
      id: item.id,
      practice_id: item.practice_id,
      link_techs: item.link_techs,
      uniqueCode: item.uniqueCode,
    });
    setOpenConfirmModal(true);
    setIsEditPatient(true);
  };
  const deletePatient = (item) => {
    paymentObject.patient_data = paymentObject.patient_data.filter(
      (data) => data.id !== item.id
    );
    let patientRowsNew = patientRows.filter((data) => data.id !== item.id);
    setTimeout(() => {
      let amoutTemp = 197;
      if (paymentObject.patient_data.length > 1) {
        amoutTemp = amoutTemp * paymentObject.patient_data.length;
      }
      if (paymentObject.patient_data.length === 0) {
        setPaymentObject({
          ...paymentObject,
          is_gift: false,
        });
      }
      setTotalAmount(amoutTemp);
    }, 200);
    setPatientRows(patientRowsNew);
    setIsDeletePatient(true);
    setOpenPatientListModal(false);
    setPaymentObjectPatientData({
      patient_name: "",
      patient_email: "",
      address: "",
      phone_number: "",
      gender: "",
      state: "",
      city: "",
      zip_code: "",
      giftedondatetime: null,
      id: "",
      practice_id: "",
      link_techs: [],
      uniqueCode: "",
    });
  };
  const addNewPatient = (event) => {
    console.log("eve", event, "paymentObject", paymentObject);
    event.preventDefault();
    setOpenConfirmModal(true);
    setPaymentObjectPatientData({
      patient_name: "",
      patient_email: "",
      address: "",
      phone_number: "",
      gender: "",
      state: "",
      city: "",
      zip_code: "",
      giftedondatetime: null,
      id: "",
      practice_id: "",
      link_techs: [],
      uniqueCode: "",
    });
    // setDateTimePickerValue(null);
  };

  const fetchPatientDetails = () => {
    let data = { patient_id: patientID };

    const reqData = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    };

    fetch(
      process.env.REACT_APP_API_URL + "asyncapi/patient-intake-details",
      reqData
    )
      .then((results) => results.json())
      .then((data) => {
        console.log("intake data results", data.intakeData.patientData);
        console.log("Hiit 77");
        if (data) {
          if (data.intakeData && data.intakeData.patientData) {
            setPatientDetails(data.intakeData.patientData);
            setPaymentObject({
              ...paymentObject,
              billing_name: data.intakeData.patientData.patient_name,
              billing_email: data.intakeData.patientData.patient_email,
              address_line_1: data.intakeData.patientData.address,
              billing_gender: data.intakeData.patientData.gender,
              // billing_state: data.intakeData.patientData.state,
              billing_city: data.intakeData.patientData.city,
              billing_phone_number: data.intakeData.patientData.phone_number,
              billing_zip: data.intakeData.patientData.zip,
            });
            setFetchPatientDetailsSuccess(true);
          }
          setloading(false);
        }
      });
  };

  useEffect(() => {
    if (isDeletePatient) {
      console.log("eve", "paymentObject", paymentObject);
      if (paymentObject.patient_data.length === 0) {
        setPaymentObject({
          ...paymentObject,
          is_gift: false,
        });
        setTotalAmount(197);
      }
    }
  }, [isDeletePatient]);

  useEffect(() => {
    if (!isGoToTop) {
      if (!isGoToTop) {
        goToTop();
      }
    }
  }, [isGoToTop]);
  useEffect(() => {
    if (fetchPatientDetailsSuccess) {
      if (locationArry && locationArry.length > 0 && patientDetails) {
        locationArry.forEach((e) => {
          if (e.label === patientDetails.state) {
            setPaymentObject({
              ...paymentObject,
              billing_state: e.abbr,
            });
          }
        });
      }
    } else {
      fetchPatientDetails();
      setloading(true);
    }
  }, [fetchPatientDetailsSuccess]);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIsGoToTop(true);
  };

  store.subscribe(() => {
    const storeData = store.getState();
    setFormSubmisionState(storeData.formSubmitSlice.formSubmisionState);
    setDiabaticPaymentDone(storeData.formSubmitSlice.diabaticPaymentDone);
    if (storeData.formSubmitSlice.diabaticPaymentNewPatientData) {
      setDiabaticPaymentNewPatientData(
        storeData.formSubmitSlice.diabaticPaymentNewPatientData
      );
    }
  });

  useEffect(() => {
    if (formSubmisionState === 2 && diabaticPaymentDone) {
      console.warn(
        "entire dataset after submitting",
        formSubmisionState,
        diabaticPaymentData
      );
      setSnakbarOpen(true);
      setSnackBarMsg("Payment Successful!");
      setSubmissionLoading(false);
      let payload = { _id: diabaticPaymentData._id };
      const reqData = {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(payload),
      };

      fetch(
        process.env.REACT_APP_API_URL + "api5/resendtransactionemail",
        reqData
      )
        .then((results) => results.json())
        .then((data) => {
          console.log("intake data results", data);
        });
      if (diabaticPaymentData.is_gift) {
        console.log("patientID", patientID);
        // setTimeout(() => navigate(`/success/${patientID}`), 2000);
        setTimeout(() => {
          navigate(`/success/${diabaticPaymentNewPatientData[0]}`);
          // let ids = [patientID];
          // fetch(process.env.REACT_APP_API_URL + "api5/deletepatientmany", ids)
          //   .then((results) => results.json())
          //   .then((data) => {
          //     console.log("intake data results", data);
          //   });
        }, 2000);
      } else {
        setTimeout(() => navigate(`/quickintake/${patientID}`), 2000);
      }
      reset();
    }
  }, [formSubmisionState, diabaticPaymentDone, diabaticPaymentDone]);

  useEffect(() => {
    console.log("formSubmisionState", formSubmisionState);
    if (formSubmisionState == 3) {
      setSnakbarOpen(true);
      setSnackBarMsg("Please add valid Card Details!");
      setSubmissionLoading(false);
    }
  }, [formSubmisionState]);

  const dateTimePickerOnChange = (value) => {
    console.log("dateTimePickerOnChange", value);
    if (value == "Invalid Date") {
      setErrorPatientData({
        ...errorPatientData,
        giftedondatetime: "Invalid Date",
      });
    } else {
      setErrorPatientData({ ...errorPatientData, giftedondatetime: "" });
    }
    setErrorPatientData({ ...errorPatientData, giftedondatetime: "" });
    setDateTimePickerValue(value.getTime());
    setPaymentObjectPatientData({
      ...paymentObjectPatientData,
      giftedondatetime: value.getTime(),
    });
  };
  const savePayment = () => {
    console.log("savePayment");
    let errorObj = {};
    if (paymentObject.holder_name == "") {
      errorObj.holder_name = "This Field is Required!";
    }
    if (paymentObject.billing_name == "") {
      errorObj.billing_name = "This Field is Required!";
    }
    if (paymentObject.billing_email == "") {
      errorObj.billing_email = "This Field is Required!";
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(paymentObject?.billing_email)) {
        errorObj.billing_email = "Please enter a valid email address";
      } else {
        delete errorObj.billing_email;
      }
    }
    if (paymentObject.billing_phone_number == "") {
      errorObj.billing_phone_number = "This Field is Required!";
    }
    if (paymentObject.billing_phone_number.length < 14) {
      errorObj.billing_phone_number =
        "Please enter a valid 10-digit phone number";
    }
    if (paymentObject.address_line_1 == "") {
      errorObj.address_line_1 = "This Field is Required!";
    }
    if (paymentObject.billing_state == "") {
      errorObj.billing_state = "This Field is Required!";
    }
    if (paymentObject.billing_city == "") {
      errorObj.billing_city = "This Field is Required!";
    }
    if (paymentObject.billing_zip == "") {
      errorObj.billing_zip = "This Field is Required!";
    }
    if (paymentObject.billing_zip.length < 5) {
      errorObj.billing_zip = "Please enter a 5-digit zip";
    }
    if (Object.keys(errorObj).length > 0) {
      setError(errorObj);
      if (document.querySelector(".form_field")) {
        let formInputFields = document.querySelectorAll(".formInput");
        if (formInputFields && formInputFields.length > 0) {
          formInputFields.forEach((e, i) => {
            e.classList.add("errorFieldAll");
          });
        }
      }
      setSubmissionLoading(false);
      setAllFieldValidate(true);
    }
    console.log("savePayment errorObj", errorObj);
    setClickedSubmit(true);
    if (Object.keys(errorObj).length === 0) {
      setAllFieldValidate(false);
    }
  };

  const getpaymentDetails = (data) => {
    console.log("savePayment");
    console.log("getpaymentDetails payload savePayment", data, paymentObject);
    if (data) {
      setClickedSubmit(false);
      if (data.type && data.type !== "validation_error") {
        if (!allFieldValidate) {
          setSubmissionLoading(true);

          let payload = {};
          // if (Object.keys(errorObj).length == 0) {
          paymentObject.card_number = paymentObject.card_number
            .split(" ")
            .join("");
          // paymentObject.card_number = parseInt(paymentObject.card_number);
          // paymentObject.cvv = parseInt(paymentObject.cvv);
          paymentObject.zip = parseInt(paymentObject.zip_code);
          // let exp_month = paymentObject.expiry_date.split("/");
          if (
            paymentObject.patient_data &&
            paymentObject.patient_data.length > 0
          ) {
            paymentObject.patient_data.forEach((e) => {
              delete e.id;
            });
          }

          Object.preventExtensions(paymentObject);
          payload = paymentObject;
          // payload.exp_month = parseInt(exp_month[0]);
          // payload.exp_year = parseInt(exp_month[1]);
          payload.amount = totalAmount * 100;
          payload.amount_roud = totalAmount;
          payload.patient_id = patientID;
          payload.giftedby = paymentObject.patient_name;

          // paymentObject.exp_month = parseInt(exp_month[0]);
          // paymentObject.exp_year = parseInt(exp_month[1]);
          paymentObject.amount = totalAmount;
          paymentObject.patient_id = patientID;
          paymentObject.giftedby = paymentObject.patient_name;
          delete payload.cvv;
          delete payload.card_number;
          delete payload.exp_month;
          delete payload.exp_year;
          delete payload.expiry_date;
          if (payload.is_gift === false) {
            delete payload.giftedby;
            delete payload.giftedondatetime;

            delete paymentObject.zip_code;
            paymentObject.address = paymentObject.address_line_1;
            paymentObject.patient_email = paymentObject.billing_email;
            paymentObject.patient_name = paymentObject.billing_name;
            paymentObject.phone_number = paymentObject.billing_phone_number;
            paymentObject.gender = paymentObject.billing_gender;
            paymentObject.state = paymentObject.billing_state;
            paymentObject.city = paymentObject.billing_city;
            paymentObject.zip = parseInt(paymentObject.billing_zip);
          }
          payload.payment_id = data.id;
          Object.preventExtensions(payload);
          // }
          console.log(
            "formDataSubmitPayment patientSubmitAllData payload",
            payload
          );
          setCardError(false);
          setCardErrorMsg(null);
          // return;
          dispatch(formDataSubmitPayment(payload));
        } else {
          setCardError(true);
          setCardErrorMsg(data.message);
        }
      } else {
        setCardError(true);
        setCardErrorMsg(data.message);
      }
    }
  };

  const cardValidateChecking = (data) => {
    if (data) {
      setCardError(false);
    }
  };
  return (
    <>
      <div className="diabaticPaymentContainer">
        <div className="diabaticPaymentSubContainer">
          <div className="diabaticPaymentSubContainerHeading">
            <img
              className="DecisionDocLogo"
              src="https://all-frontend-assets.s3.amazonaws.com/Diabetes-rexburg-decision-doc/dia_rxbrg/decision_doc_logo.webp"
              alt=""
            />
            <h1>Secure Your Health: TMFlow Diabetic Report Payment</h1>
          </div>
          <div className="paymentSection">
            <div className="billingSection">
              {loading ? (
                <>
                  {" "}
                  <LinearProgress /> <br />{" "}
                </>
              ) : null}
              {/* <div className="patientInformation">
              <h2>Patient Information</h2>
              <div className="patientInformationContent">
                <div className="patientInformationName">
                  Patient Name : <span>{patientDetails?.patient_name}</span>
                </div>
                <div className="patientInformationEmail">
                  Patient Email : <span>{patientDetails?.patient_email}</span>
                </div>
              </div>
            </div> */}
              <form autoComplete="off" id="myForm">
                <div className="billingDetails">
                  <h2>Billing Details</h2>
                  <div className="emailAddressDiv">
                    <Controller
                      name="billing_name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={
                            paymentObject?.billing_name
                              ? paymentObject.billing_name
                              : ""
                          }
                          error={Boolean(error?.billing_name)}
                          onChange={handleInput}
                          className="billingName formInput"
                          label="Billing Name"
                          helperText={
                            error.billing_name && (
                              <span>
                                <span>{error?.billing_name}</span>
                              </span>
                            )
                          }
                        />
                      )}
                    />

                    <Controller
                      name="billing_email"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="billingEmail formInput"
                          onChange={handleInput}
                          error={Boolean(error?.billing_email)}
                          value={
                            paymentObject?.billing_email
                              ? paymentObject.billing_email
                              : ""
                          }
                          label="Billing Email"
                          helperText={
                            error.billing_email && (
                              <span>
                                <span>{error?.billing_email}</span>
                              </span>
                            )
                          }
                        />
                      )}
                    />
                  </div>
                  <div className="emailAddressDiv">
                    <Controller
                      name="billing_gender"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl
                          error={Boolean(error?.billing_gender)}
                          className={`formInput billingAddress gender_dropdown`}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Gender
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={
                              paymentObject?.billing_gender
                                ? paymentObject.billing_gender
                                : ""
                            }
                            label="Gender"
                            onChange={handleChange}
                          >
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="others">Others</MenuItem>
                          </Select>
                          <FormHelperText>
                            {genderErrorMessage[error.billing_gender?.type]}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />

                    {/* <TextField
                    id="outlined-basic"
                    type="text"
                    name="billing_gender"
                    onChange={handleInput}
                    className="formInput billingAddress"
                    value={
                      paymentObject?.billing_gender
                        ? paymentObject.billing_gender
                        : ""
                    }
                    error={error.billing_gender ? true : false}
                    helperText={error.billing_gender}
                    label="Gender"
                    variant="outlined"
                    onKeyDown={() => {
                      error.billing_gender = "";
                    }}
                  /> */}
                    <TextField
                      id="outlined-basic"
                      type="text"
                      name="billing_phone_number"
                      onChange={handleInput}
                      className="formInput billingAddress"
                      rules={{ required: true, maxLength: 14, minLength: 14 }}
                      value={
                        paymentObject?.billing_phone_number
                          ? paymentObject.billing_phone_number
                          : ""
                      }
                      error={error.billing_phone_number ? true : false}
                      helperText={error.billing_phone_number}
                      label="Phone No"
                      variant="outlined"
                      onKeyDown={() => {
                        error.billing_phone_number = "";
                      }}
                    />

                    <TextField
                      id="outlined-basic"
                      type="text"
                      name="address_line_1"
                      onChange={handleInput}
                      className="formInput billingAddress"
                      value={
                        paymentObject?.address_line_1
                          ? paymentObject.address_line_1
                          : ""
                      }
                      error={error.address_line_1 ? true : false}
                      helperText={error.address_line_1}
                      label="Billing Address"
                      variant="outlined"
                      onKeyDown={() => {
                        error.address_line_1 = "";
                      }}
                    />
                  </div>

                  <div className="stateCityZipDiv">
                    <TextField
                      id="outlined-basic"
                      type="text"
                      name="billing_city"
                      onChange={handleInput}
                      className="formInput billingCity"
                      value={
                        paymentObject?.billing_city
                          ? paymentObject.billing_city
                          : ""
                      }
                      error={error.billing_city ? true : false}
                      helperText={error.billing_city}
                      label="City"
                      variant="outlined"
                      onKeyDown={() => {
                        error.billing_city = "";
                      }}
                    />
                    <FormControl
                      variant="filled"
                      sx={{ m: 1, minWidth: 120 }}
                      className={`formInput billingState`}
                    >
                      <InputLabel id="demo-simple-select-filled-label">
                        Select State
                      </InputLabel>
                      <Select
                        id="demo-simple-select-helper"
                        name="billing_state"
                        // className={`${styles.flexDivInput} formInput`}
                        value={
                          paymentObject?.billing_state
                            ? paymentObject.billing_state
                            : ""
                        }
                        onChange={stateDropDown}
                        error={error.billing_state ? true : false}
                        helperText={error.billing_state}
                      >
                        {dropDownData &&
                          dropDownData.map((item, key) => {
                            return (
                              <MenuItem
                                value={item.abbr}
                                key={key}
                                onClick={() => selectState(item)}
                              >
                                {item.label}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {/* {error.state && !isStateValid && (
                    <FormHelperText>This Field is Required!</FormHelperText>
                  )} */}
                    </FormControl>
                    <TextField
                      id="outlined-basic"
                      type="number"
                      name="billing_zip"
                      onChange={handleInput}
                      className={`formInput billingZip`}
                      rules={{ required: true, maxLength: 5, minLength: 5 }}
                      value={
                        paymentObject?.billing_zip
                          ? paymentObject.billing_zip
                          : ""
                      }
                      error={error.billing_zip ? true : false}
                      helperText={error.billing_zip}
                      label="Zip"
                      variant="outlined"
                      onKeyDown={() => {
                        error.billing_zip = "";
                      }}
                    />
                  </div>
                </div>
                <div className="giftTextSection">
                  <span>This is gift for someone special : </span>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_gift"
                        onChange={handleInput}
                        className={`formInput billingGift`}
                        checked={paymentObject?.is_gift}
                        value={
                          paymentObject?.is_gift ? paymentObject.is_gift : ""
                        }
                        error={error.is_gift ? true : false}
                        helperText={error.is_gift}
                      />
                    }
                  />
                  {openConfirmModal}
                  {paymentObject &&
                  paymentObject.is_gift &&
                  !openConfirmModal ? (
                    <>
                      <span>Recipient : </span>
                      <p className="patientName">
                        {paymentObject &&
                          paymentObject.patient_data &&
                          paymentObject.patient_data.length > 0 &&
                          paymentObject.patient_data[0].patient_name}
                        {paymentObject.patient_data &&
                          paymentObject.patient_data.length > 1 && (
                            <span
                              className="allPatientCountSpan"
                              title={`${
                                paymentObject.patient_data.length - 1
                              } More Patient`}
                            >
                              +{paymentObject.patient_data.length - 1}
                            </span>
                          )}
                        <span
                          onClick={() => editGiftDetail()}
                          className="iconedit"
                        >
                          <Tooltip title="Edit Gift Details">
                            <CreateSharpIcon />
                          </Tooltip>
                        </span>
                      </p>
                      {paymentObject.patient_data &&
                        paymentObject.patient_data.length > 0 && (
                          <button
                            className="addPatientButton"
                            onClick={addNewPatient}
                          >
                            Add New
                          </button>
                        )}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="cardDetails">
                  <h2>Card Details</h2>
                  <div className="cardDetailsContent">
                    <div className="cardDetailsLeft">
                      <TextField
                        id="outlined-basic"
                        type="text"
                        name="holder_name"
                        onChange={handleInput}
                        className="formInput holderName"
                        value={
                          paymentObject?.holder_name
                            ? paymentObject.holder_name
                            : ""
                        }
                        error={error.holder_name ? true : false}
                        helperText={error.holder_name}
                        label="Name On Card"
                        variant="outlined"
                        onKeyDown={() => {
                          error.holder_name = "";
                        }}
                      />
                      <PaymentComponent
                        clicked={clickedSubmit}
                        allFieldValidated={allFieldValidate}
                        paymentObjectData={paymentObject}
                        paymentID={getpaymentDetails}
                        cardvalidated={cardValidateChecking}
                      />
                      {cardError && (
                        <span className="cardError">
                          <span className="cardErrorMsg">{cardErrorMsg}</span>
                        </span>
                      )}
                      {/* <TextField
                      id="outlined-basic"
                      type="text"
                      name="card_number"
                      onChange={handleInput}
                      className="formInput cardNumber"
                      value={
                        paymentObject?.card_number
                          ? paymentObject.card_number
                          : ""
                      }
                      error={error.card_number ? true : false}
                      helperText={error.card_number}
                      label="Card Number"
                      variant="outlined"
                      onKeyDown={() => {
                        error.card_number = "";
                      }}
                    />
                    <div className="expireDetailsDiv">
                      <TextField
                        id="outlined-basic"
                        type="text"
                        name="expiry_date"
                        onChange={handleInput}
                        className={`formInput expiryDateField `}
                        value={
                          paymentObject?.expiry_date
                            ? paymentObject.expiry_date
                            : ""
                        }
                        error={error.expiry_date ? true : false}
                        helperText={error.expiry_date}
                        label="MM/YY"
                        variant="outlined"
                        onKeyDown={() => {
                          error.expiry_date = "";
                        }}
                      />
                      <TextField
                        id="outlined-basic"
                        type={showPassword ? "text" : "password"}
                        name="cvv"
                        onChange={handleInput}
                        className={`formInput cvvField`}
                        value={paymentObject?.cvv ? paymentObject.cvv : ""}
                        error={error.cvv ? true : false}
                        helperText={error.cvv}
                        label="CVV"
                        variant="outlined"
                        onKeyDown={() => {
                          error.cvv = "";
                        }}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </div> */}
                    </div>
                    <div className="cardDetailsRight">
                      <p>all major Credit card and debit card accepted:</p>
                      <div className="allCardList">
                        <div className="allCardListTop">
                          <img
                            className="praticeLogo"
                            src={AmeriacanExpress}
                            alt=""
                          />
                          {/* <img
                            className="praticeLogo"
                            src={DinersClub}
                            alt=""
                          /> */}
                          <img className="praticeLogo" src={Discover} alt="" />
                          <img className="praticeLogo" src={JCB} alt="" />
                        </div>
                        <div className="allCardListBottom">
                          <img
                            className="praticeLogo"
                            src={MasterCard}
                            alt=""
                          />
                          <img className="praticeLogo" src={Visa} alt="" />
                          {/* <img className="praticeLogo" src={UnionPay} alt="" /> */}
                        </div>
                      </div>
                      {/* <img className="praticeLogo" src={CardImage} alt="" /> */}
                    </div>
                  </div>
                  {/* <Form
                onSubmit={handleSubmit(onSubmit)}
                formData={DiabaticsPaymentForm}
              /> */}
                </div>
              </form>
            </div>
            <div className="orderSummarySection">
              <div className="orderSummarySectionContent">
                <div className="orderSummaryDetailsSection">
                  <h2>Order Summary</h2>
                  <div className="orderSummaryDetailsSectionContent">
                    <h4>Item: </h4>
                    <p>
                      TMFlow Health Risk Exam Diabetes Report{" "}
                      <span>${totalAmount}</span>
                    </p>
                  </div>
                </div>
                <div className="priceDetailsSection">
                  <h2>Price Details</h2>
                  <div className="priceDetailsSectionContent">
                    <p>
                      Price(1 item) <span>$197</span>
                    </p>
                    <p>
                      Item Purchased{" "}
                      <span>
                        {paymentObject.patient_data.length === 0 ||
                        paymentObject.patient_data.length === 1
                          ? 1
                          : paymentObject.patient_data.length}
                      </span>
                    </p>
                    <hr />
                    <p>
                      <strong>
                        Total Amount <span>${totalAmount}</span>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="paybutton">
                <Button onClick={savePayment}>Submit</Button>
              </div>
              {submissionLoading ? <LinearProgress /> : null}
            </div>
          </div>
        </div>
        <div className="signature">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalPop"
            open={openConfirmModal}
            onClose={modalClose}
            closeAfterTransition
          >
            <Fade in={openConfirmModal}>
              <Box sx={style} className="modalContent signUp_modal giftModal">
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Details of the Gift Recipient
                    <Button
                      onClick={() => modalClose()}
                      variant="contained"
                      className="btnClose"
                    >
                      <CloseIcon />
                    </Button>
                  </DialogContentText>
                  <div className="giftModalContentainer">
                    <div className="giftModalContent">
                      <TextField
                        id="outlined-basic"
                        type="text"
                        name="patient_name"
                        onChange={handleInput}
                        className={`formInput giftedby`}
                        value={
                          paymentObjectPatientData?.patient_name
                            ? paymentObjectPatientData.patient_name
                            : ""
                        }
                        error={errorPatientData.patient_name ? true : false}
                        helperText={errorPatientData.patient_name}
                        label="Enter Name"
                        variant="outlined"
                        onKeyDown={() => {
                          errorPatientData.patient_name = "";
                        }}
                      />
                      <Controller
                        name="patient_email"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="giftedbyemail formInput"
                            onChange={handleInput}
                            error={Boolean(errorPatientData?.patient_email)}
                            value={
                              paymentObjectPatientData?.patient_email
                                ? paymentObjectPatientData.patient_email
                                : ""
                            }
                            label="Enter Email"
                            helperText={
                              errorPatientData.patient_email && (
                                <span>{errorPatientData.patient_email}</span>
                              )
                            }
                          />
                        )}
                      />
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            error={Boolean(errorPatientData?.gender)}
                            className="gender_dropdown"
                          >
                            <InputLabel id="demo-simple-select-label">
                              Gender
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={
                                paymentObjectPatientData?.gender
                                  ? paymentObjectPatientData.gender
                                  : ""
                              }
                              label="Gender"
                              onChange={handleChange}
                            >
                              <MenuItem value="female">Female</MenuItem>
                              <MenuItem value="male">Male</MenuItem>
                              <MenuItem value="others">Others</MenuItem>
                            </Select>
                            <FormHelperText>
                              {errorPatientData.gender}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="phone_number"
                        control={control}
                        defaultValue=""
                        // rules={{ required: true, minLength: 10, maxLength: 10 }}
                        rules={{ required: true, maxLength: 14, minLength: 14 }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={setPhoneNumber}
                            value={
                              paymentObjectPatientData?.phone_number
                                ? paymentObjectPatientData.phone_number
                                : ""
                            }
                            error={Boolean(errorPatientData?.phone_number)}
                            label="Phone No"
                            helperText={
                              errorPatientData.phone_number && (
                                <span>{errorPatientData.phone_number}</span>
                              )
                            }
                            inputProps={{
                              maxLength: 14,
                              minLength: 14,
                            }}
                          />
                        )}
                      />
                      <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={handleInput}
                            error={Boolean(errorPatientData?.address)}
                            value={
                              paymentObjectPatientData?.address
                                ? paymentObjectPatientData.address
                                : ""
                            }
                            label="Address"
                            helperText={
                              errorPatientData.address && (
                                <span>Address is Required</span>
                              )
                            }
                          />
                        )}
                      />
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={handleInput}
                            value={
                              paymentObjectPatientData?.city
                                ? paymentObjectPatientData.city
                                : ""
                            }
                            error={Boolean(errorPatientData?.city)}
                            label="City"
                            helperText={
                              errorPatientData.city && (
                                <span>{errorPatientData.city}</span>
                              )
                            }
                          />
                        )}
                      />
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                          <FormControl
                            fullWidth
                            error={Boolean(errorPatientData?.state)}
                            className={`formInput billingState`}
                          >
                            <InputLabel id="demo-simple-select-filled-label">
                              Select State
                            </InputLabel>
                            <Select
                              id="demo-simple-select-helper"
                              value={
                                paymentObjectPatientData?.state
                                  ? paymentObjectPatientData.state
                                  : ""
                              }
                              onChange={stateDropDown}
                              error={errorPatientData.state ? true : false}
                              helperText={errorPatientData.state}
                            >
                              {dropDownData &&
                                dropDownData.map((item, key) => {
                                  return (
                                    <MenuItem
                                      value={item.abbr}
                                      key={key}
                                      onClick={() => selectState(item)}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText>
                              {errorPatientData.state}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      <TextField
                        id="outlined-basic"
                        type="number"
                        name="zip_code"
                        onChange={handleInput}
                        className={`formInput billingZip`}
                        value={
                          paymentObjectPatientData?.zip_code
                            ? paymentObjectPatientData.zip_code
                            : ""
                        }
                        error={errorPatientData.zip_code ? true : false}
                        helperText={errorPatientData.zip_code}
                        label="Zip"
                        variant="outlined"
                        onKeyDown={() => {
                          errorPatientData.zip_code = "";
                        }}
                      />
                      <div className="dateTimeField">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            label="Gifted Date Time"
                            defaultValue={new Date().getTime()}
                            //                           minDate={new Date()}
                            // ampm={false}
                            // value={dateTimePickerValue}
                            value={
                              paymentObjectPatientData.giftedondatetime
                              // ? paymentObjectPatientData.giftedondatetime
                              // : currentTime
                            }
                            error={
                              errorPatientData.giftedondatetime ? true : false
                            }
                            slotProps={{
                              textField: {
                                inputProps: {
                                  readOnly: true,
                                },
                              },
                            }}
                            onChange={(newValue) => {
                              dateTimePickerOnChange(newValue);
                            }}
                            disablePast={true}
                            closeOnSelect={false}
                          />
                          {errorPatientData.giftedondatetime && (
                            <FormHelperText className="Mui-error">
                              {errorPatientData.giftedondatetime}
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      </div>
                    </div>

                    {patientSubmitModalLoading ? (
                      <>
                        {" "}
                        <LinearProgress /> <br />{" "}
                      </>
                    ) : null}
                    <DialogActions>
                      <Button
                        onClick={agreeComfirmation}
                        variant="contained"
                        className="btnmdl"
                      >
                        Save
                      </Button>
                      {/* <Button
                            onClick={agreeComfirmation}
                            variant="contained"
                            className="btnmdl"
                          >
                            Cancel
                          </Button> */}
                    </DialogActions>
                  </div>
                </DialogContent>
                {/* {patientSubmitModalLoading ? (
                <>
                  {" "}
                  <LinearProgress /> <br />{" "}
                </>
              ) : null}
              <DialogActions>
                <Button
                  onClick={agreeComfirmation}
                  variant="contained"
                  className="btnmdl"
                >
                  Save
                </Button>
            
              </DialogActions> */}
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modalPop"
            open={openPatientListModal}
            onClose={patientListModalClose}
            closeAfterTransition
          >
            <Fade in={openPatientListModal}>
              <Box
                sx={style}
                className="modalContent signUp_modal giftModal patientList RecipientModal"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Gift Recipient List
                    <Button
                      onClick={() => patientListModalClose()}
                      variant="contained"
                      className="btnClose"
                    >
                      <CloseIcon />
                    </Button>
                  </DialogContentText>
                  <div className="giftModalContentainer">
                    <div className="giftModalContent">
                      {paymentObject &&
                        paymentObject.patient_data &&
                        paymentObject.patient_data.length > 0 && (
                          <>
                            {/* <table>
                            <tbody>
                              {paymentObject.patient_data.map(function (
                                value,
                                key
                              ) {
                                return (
                                  <tr className="gradeA" role="row" key={key}>
                                    <td className="sorting_1">
                                      {value.patient_name}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table> */}
                            <DataGrid
                              rows={patientRows}
                              columns={columns}
                              initialState={{
                                pagination: {
                                  paginationModel: { page: 0, pageSize: 5 },
                                },
                              }}
                              pageSizeOptions={[5, 10]}
                              disableRowSelectionOnClick={true}
                              rowSelection={false}
                              ignoreRowClick={true}
                            />
                            {/* <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 700 }}
                              aria-label="customized table"
                            >
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>
                                    Dessert (100g serving)
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    Calories
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    Fat&nbsp;(g)
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    Carbs&nbsp;(g)
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    Protein&nbsp;(g)
                                  </StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {patientRows.map((row) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                      {row.patient_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.patient_email}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.patient_email}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.patient_email}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      {row.patient_email}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer> */}
                          </>
                        )}
                    </div>
                  </div>
                </DialogContent>
              </Box>
            </Fade>
          </Modal>
        </div>
        <Snackbar
          className="loginSnack Snackbar_center"
          autoHideDuration={3000}
          open={snakbaropen}
          message={snackBarMsg}
          key="bottomcenter"
        />
      </div>
      <Footer />
    </>
  );
};

export default DiabaticsPayment;
