import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const fetchUniqueData = createAsyncThunk("fetchUniqueData", async(body) => {

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "api5/finduniqueid", requestOptions);
    const ress = await response.json();
    return ress;
});

export const fetchUserDatas = createAsyncThunk("fetchUserDatas", async(body) => {

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/fetch-practice-name", requestOptions);
    const ress = await response.json();
    return ress;
});
export const submitRewardSignature = createAsyncThunk("submitRewardSignature", async(body) => {

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "api3/contractSign", requestOptions);
    const ress = await response.json();
    return ress;
});


export const fetchUserDetails = createAsyncThunk("fetchUserDetails", async(body) => {

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "api5/fetchPracticeandTech", requestOptions);
    const ress = await response.json();
    return ress;
});

export const checkUniqueCodeAvailability = createAsyncThunk("checkUniqueCodeAvailability", async(body) => {

    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    const response = await fetch(process.env.REACT_APP_API_URL + "api5/checkuniqueid", requestOptions);
    const ress = await response.json();
    return ress;
});

const RewardReducer = createSlice({
    name: "RewardReducer",
    initialState: {
        loading: false,
        success: false,
        error: false,
        message: "",
        userData: null,
        userDetails: null,
        uniqueDetails: null,
        uniqueDetailsMsg: null,
        uniqueCodeAvailability: false
    },
    reducers: {
        resetSuccessStatus(state, action) {
            state.success = null
        }
    },
    extraReducers: {

        [fetchUniqueData.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchUniqueData.fulfilled]: (state, action) => {
            console.log("fetchUniqueData action", action.payload);
            if (action.payload.status) {
                if (action.payload.result && action.payload.result.length > 0) {
                    state.uniqueDetails = action.payload.result[0];
                } else {
                    state.uniqueDetails = action.payload.result;
                }
                if (action.payload.msg) {
                    state.uniqueDetailsMsg = action.payload.msg;
                }
                state.loading = false;
            }
            if (action.payload.status === "error") {
                state.message = action.payload.message;
            }
        },
        [fetchUniqueData.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchUserDatas.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchUserDatas.fulfilled]: (state, action) => {
            console.log("partnerData action", action.payload.results.res[0]);
            if (action.payload.status) {
                state.userData = action.payload.results.res[0];
                state.loading = false;
            }
            if (action.payload.status === "error") {
                state.message = action.payload.message;
            }
        },
        [fetchUserDatas.rejected]: (state, action) => {
            state.loading = false;
        },
        [submitRewardSignature.pending]: (state, action) => {
            state.loading = true;
        },
        [submitRewardSignature.fulfilled]: (state, action) => {
            console.log("partnerData action", action.payload);
            if (action.payload.status) {
                state.success = true
                state.message = action.payload.message;
                state.loading = false;
                state.userData = action.payload.results;
            }
            if (action.payload.status === "error") {
                state.message = action.payload.message;
            }
        },
        [submitRewardSignature.rejected]: (state, action) => {
            state.loading = false;
        },
        [fetchUserDetails.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchUserDetails.fulfilled]: (state, action) => {
            console.log("fetchUserDetails action", action.payload);
            if (action.payload.status) {
                state.success = true
                state.userDetails = action.payload.res;
                state.loading = false;
            }
            if (action.payload.status === "error") {
                state.message = action.payload.message;
            }
        },
        [fetchUserDetails.rejected]: (state, action) => {
            state.loading = false;
        },
        [checkUniqueCodeAvailability.pending]: (state, action) => {
            state.loading = true;
        },
        [checkUniqueCodeAvailability.fulfilled]: (state, action) => {
            console.log("checkUniqueCodeAvailability action", action.payload);
            if (action.payload.status) {
                state.success = true
                state.uniqueCodeAvailability = action.payload.count;
                state.loading = false;
            }
            if (action.payload.status === "error") {
                state.message = action.payload.message;
            }
        },
        [checkUniqueCodeAvailability.rejected]: (state, action) => {
            state.loading = false;
        },

    }
});

export const { resetSuccessStatus } = RewardReducer.actions;
export default RewardReducer.reducer;