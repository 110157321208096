import React from 'react';
import ReactDOM from 'react-dom';

const Footer = () => {

  return (
    <>
      <div className="footer_rxbrg">
        <div className="common_width">
          <div className="foooter_rxbrg_bdy">
            <p>
              <a href='/terms-and-condition' >Terms & Conditions</a> |{" "}
              <a href='/privacy-policy' >Privacy Policy</a> |{" "}
              Copyright © 2023
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;