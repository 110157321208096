import { configureStore } from "@reduxjs/toolkit";
import practiceReducer from "./Components/practiceReducer";
import formSubmitSlice from "./Components/SignupFormReducer";
import intakeFormSubmitSlice from "./Components/intakeReducer"
import AppointmentBookingReducer from "./Components/Appointment-Booking/AppointmentBookingReducer";
import SlotCancelReducer from "./Components/Slot-Cancel/SlotCancelReducer";
import formSlice from './form/formReducer';
import tableSlice from "./listing/listReducer"
import RewardReducer from "./Components/Share4rewards/RewardReducer";
import RewardDashboardReducer from "./Components/Share4rewards/RewardDashboard/RewardDashboardReducer";
import RewardLoginReducer from "./Components/Share4rewards/RewardsLogin/RewardLoginReducer";
import ResolveReducer from "./helper/ResolveReducer";



export default configureStore({
    reducer: {
        practiceReducer: practiceReducer,
        formSubmitSlice: formSubmitSlice,
        appointmentBokkingReducer: AppointmentBookingReducer,
        intakeFormSubmitSlice: intakeFormSubmitSlice,
        slotCancelReducer: SlotCancelReducer,
        formSlice: formSlice,
        tableSlice: tableSlice,
        rewardReducer: RewardReducer,
        RewardDashboardReducer: RewardDashboardReducer,
        rewardLoginReducer: RewardLoginReducer,
        ResolveReducer: ResolveReducer
    },
});