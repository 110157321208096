import "../Diabatics/Diabatics.css";
import {
  Box,
  Button,
  LinearProgress,
  Modal,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "../../form/Form";
import store from "../../store";
import PrivacyModal from "../modal/PrivacyModal";
import TermsModal from "../modal/TermsModal";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  checkUniqueCodeAvailability,
  fetchUniqueData,
  fetchUserDetails,
} from "../Share4rewards/RewardReducer";
import {
  formDataSubmitGoogleEvent,
  setFormSubmitStateReset,
} from "../SignupFormReducer";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useRef } from "react";
import ReactPlayer from "react-player";
import Footer from "../../Layout/Footer";
import Head from "react";
import { Helmet } from "react-helmet";

const Diabatics = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const [loading, setloading] = useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [snakbaropen, setSnakbarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const navigate = useNavigate();
  const [locationArry, setLocationArry] = useState([]);
  const [linklocationArry, setLinkLocationArry] = useState([]);
  const [linklocationArryFlag, setLinkLocationArryFlag] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormDataCookie] = useCookies(["formsubmitdata"]);
  const [dataSubmissionId, setDataissionIdCookie] = useCookies([
    "formsubmitdata",
  ]);
  // const [formSubmisionState, setFormSubmisionState] = useState(0);
  // const [formDataState, setFormDatastate] = useState(null);
  const [isNoParam, setIsNoParam] = useState(false);
  const [uniqueCode, setUniqueCode] = useState("");
  const [checkingUniqueCode, setCheckingUniqueCode] = useState(false);
  const [errorInUniqueCode, setErrorInUniqueCode] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [checkPrivacy, setCheckPrivacy] = useState(false);

  const [checkTermsToogle, setCheckTermsToogle] = useState(false);
  const [checkPrivacyToogle, setCheckPrivacyToogle] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);

  const logoPlaceholder = "src/assets/logo_placeholder.png";
  const ref = useRef(null);

  const cookies = new Cookies();
  const practiceId = cookies.get("practiceIds");

  const { uniqueID } = useParams();
  console.log("uniqueID ", uniqueID);
  console.log("props====>>>", props);

  const [reload, setReload] = useState(false);

  const uniqueDetails = useSelector((state) =>
    state.rewardReducer && state.rewardReducer.uniqueDetails
      ? state.rewardReducer.uniqueDetails
      : null
  );

  const DiabaticsFormSubmissionState = useSelector((state) =>
    state.formSlice.formSubmissionState &&
      state.formSlice.formSubmissionState.DiabaticsForm
      ? state.formSlice.formSubmissionState.DiabaticsForm
      : null
  );
  const DiabaticsFormmObj = useSelector((state) =>
    state.formSlice.formData && state.formSlice.formData.DiabaticsForm
      ? state.formSlice.formData.DiabaticsForm
      : null
  );
  const userDetails = useSelector((state) =>
    state.rewardReducer && state.rewardReducer.userDetails
      ? state.rewardReducer.userDetails
      : null
  );
  const uniqueCodeAvailability = useSelector((state) =>
    state.rewardReducer && state.rewardReducer.uniqueCodeAvailability
      ? state.rewardReducer.uniqueCodeAvailability
      : null
  );
  const apiLoading = useSelector((state) =>
    state.rewardReducer && state.rewardReducer.loading
      ? state.rewardReducer.loading
      : false
  );
  const uniqueDetailsMessage = useSelector((state) =>
    state.rewardReducer && state.rewardReducer.uniqueDetailsMsg
      ? state.rewardReducer.uniqueDetailsMsg
      : null
  );

  const formDataState = useSelector((state) =>
    state.formSubmitSlice && state.formSubmitSlice.submissionSucessData
      ? state.formSubmitSlice.submissionSucessData
      : null
  ); //storeData.formSubmitSlice.submissionSucessData

  const onFetchState = async (value) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ search_str: value ? value : "" }),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api9/fetch-allstates",
      requestOptions
    );

    console.log("response state here >>>>>", response);
    const resResult = await response.json();
    const data = resResult.sates.map((data) => {
      return { val: data.name, name: data.name };
    });
    setLocationArry(data);
  };

  useEffect(() => {
    onFetchState();
    setCheckPrivacyToogle(false);
    setCheckTermsToogle(false);
    console.log("cookies", cookies);
    if (cookies && cookies.cookies && cookies.cookies.formsubmitdata) {
      cookies.remove("formsubmitdata", { path: "/" });
      cookies.remove("techIds", { path: "/" });
    }
    dispatch(setFormSubmitStateReset());
  }, []);

  // useEffect(() => {
  //     if (uniqueID) {
  //         console.log("uniqueID ", uniqueID);
  //         let data = {
  //             condition: {
  //                 diabatics_unique_name: uniqueID,
  //                 user_type: "practice",
  //             },
  //         };
  //         dispatch(fetchUniqueData(data));
  //     } else {
  //         setIsNoParam(true)
  //     }
  // }, [uniqueID]);

  // useEffect(() => {
  //     // if (uniqueDetails) {
  //     console.log(uniqueDetails, 'uniqueDetails')

  //     // dispatch(fetchUserDetails(data));
  //     // }
  //     if (uniqueDetails && !apiLoading) {
  //         // setBannerLoading(false);
  //         if (uniqueDetails.length === 0) {
  //             setSnakbarOpen(true);
  //             setSnackBarMsg(uniqueDetailsMessage);
  //             setIsNoParam(true);
  //         } else {
  //             let data = { _id: uniqueDetails._id };

  //             dispatch(fetchUserDetails(data));
  //         }
  //         if (uniqueDetails?.link_location && uniqueDetails.link_location != null && uniqueDetails.link_location !== '') {
  //             let linklocArry = []
  //             for (const key in uniqueDetails.practice_details.location_details) {
  //                 if (uniqueDetails.practice_details.location_details[key].location_name == uniqueDetails.link_location) {

  //                     linklocArry.push(uniqueDetails.practice_details.location_details[key]);
  //                 }
  //             }
  //             console.warn("linklocArry>>>>>>>", linklocArry);
  //             setLinkLocationArry(linklocArry);
  //             setLinkLocationArryFlag(true)
  //         }

  //     }
  // }, [uniqueDetails]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    // p: 4,
  };

  useEffect(() => {
    console.log("userDetails", userDetails);
  }, [userDetails]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (DiabaticsFormSubmissionState === 4) {
      console.log("RewardsFormObj", DiabaticsFormmObj);
      onSubmit(DiabaticsFormmObj);
    }
  }, [DiabaticsFormSubmissionState]);

  useEffect(() => {
    // if (checkingUniqueCode && !apiLoading) {
    //     if (uniqueCodeAvailability) {
    //         navigate(`/${uniqueCode}`)
    //         setIsNoParam(false);
    //         setloading(false);
    //     } else {
    //         setErrorInUniqueCode(true);
    //         setloading(false);
    //     }
    // }
  }, [checkingUniqueCode, apiLoading, uniqueCodeAvailability]);

  // console.log("DiabaticsFormSubmissionState", formDataState);
  useEffect(() => {
    console.log("formDataState", formDataState, reload);
    if (reload && formDataState && Object.keys(formDataState).length > 0) {
      setSnakbarOpen(true);
      setSnackBarMsg("Added Successfully");
      setDataissionIdCookie("formsubmitdata", formDataState, { path: "/" });
      setTimeout(() => navigate(`/payment/${formDataState._id}`), 2000);
      // setTimeout(() => navigate(`/appointment-booking/${formDataState._id}`), 2000);
      setReload(false);
      setloading(false);

      // dispatch(setFormSubmitStateReset());
      reset();
    } else {
      // console.log("formDataState", formDataState);
      setloading(false);
      // setSnakbarOpen(true);
      // setSnackBarMsg("Added Successfully");
    }
  }, [formDataState, reload]);

  if (snakbaropen) {
    setTimeout(() => {
      setSnakbarOpen(false);
    }, 2000);
  }

  //form submission
  const onSubmit = (dataccc) => {
    // setFormDataCookie("formsubmitdata", data, { path: "/" });
    let data = JSON.parse(JSON.stringify(dataccc));
    data.is_pecemarketing = 1; // for identify dark-theme
    data.is_pecemarketingsymptom = true;
    data.paging_step = 1;
    if (props) {
      if (props.practiceData) {
        data.uniqueCode =
          props.practiceData.location_details &&
            props.practiceData.location_details.length > 0
            ? props.practiceData.location_details[0].location_name.replaceAll(
              " ",
              ""
            )
            : "DiabetesRexburg";
        data.practice_id = props.practiceData._id;
        data.link_by = props.practiceData._id;
        data.link_location =
          props.practiceData.location_details[0].location_name;
      }
      if (props.practiceName) {
        data.practice_name = props.practiceName;
      }
      if (props.techData) {
        data.link_techs = props.techData;
      }
    }
    data.link_sub_id = [];
    data.paging_step_one_submission = new Date().getTime();
    data.domain = "www.diabetesrexburg.com";
    data.domain_url = "diabetesrexburg";
    data.email = data.email.toLowerCase().trim();
    // data.link_location =
    //   userDetails.practice_details[0].link_location
    //     ? userDetails.practice_details[0].link_location
    //     : "";

    // data.link_location =
    //     uniqueDetails.link_location                               /// Chnages on 1.3.2023
    //         ? uniqueDetails.link_location
    //         : "";
    delete data.formId;
    setFormSubmitLoading(true);
    setReload(true);

    console.log("formDataSubmitGoogleEvent", data);

    dispatch(formDataSubmitGoogleEvent(data));

    reset();
  };

  const DiabaticsForm = {
    id: "DiabaticsForm",
    formtype: "add",
    // api_url: process.env.REACT_APP_API_URL,
    // endPoint: "landing-page-manage/add-user",
    // endPointBodyWrapper: "data",
    // urlPathOnSuccessfulFormSubmission: "/calendar",
    msgOnFailedFormSubmission: "Something Went Wrong",
    formWrapperClass: "DiabaticsFormWrapperClass", // Can be undefined default will be formCls
    formInlineCLass: "userForm", // Can be undefined default will be formInline
    formButtonClass: "submitbtnsection",
    submitBtnName: "Submit",
    // submitBtnStartIcon: 'image_search_icon',
    // submitBtnEndIcon: 'search_icon',
    resetBtnName: "Reset",
    formAdditionalSubmissionData: {},
    fields: [
      //////////////////////////////////// Text Input ///////////////////////////////
      {
        id: 0,
        // heading: "Full Name",
        label: "First Name*",
        name: "firstname",
        className: "formfild",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 1,
        // heading: "Full Name",
        label: "Last Name*",
        name: "lastname",
        className: "formfild",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 8,
        // heading: "Full Name",
        label: "Gender*",
        name: "gender",
        className: "formfild",
        type: "select",
        rules: { required: true },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
        },
        values: [
          { val: "male", name: "Male" },
          { val: "female", name: "Female" },
          { val: "other", name: "Other" },
        ],
      },

      {
        id: 2,
        // heading: "Full Name",
        label: "Email Address*",
        name: "email",
        className: "formfild",
        type: "text",
        inputType: "email",
        rules: { required: true },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 3,
        // heading: "Full Name",
        label: "Phone No*",
        name: "phone_number",
        className: "formfild",
        type: "text",
        inputType: "phone",
        rules: { required: true, maxLength: 14, minLength: 14 },

        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
          maxLength: "Please enter a valid 10-digit phone number",
          minLength: "Please enter a valid 10-digit phone number",
        },
      },

      {
        id: 4,
        label: "Address*",
        name: "address",
        className: "formfild",
        type: "text",
        inputType: "text", /////////////// If not mentioned default will be text //////////////
        rules: { required: true },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
        },
      },

      {
        id: 5,
        // heading: "Full Name",
        label: "City*",
        name: "city",
        className: "formfild",
        type: "text",
        rules: { required: true },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
        },
      },
      {
        id: 6,
        // heading: "Full Name",
        label: "State*",
        name: "state",
        className: "formfild state",
        type: "select",
        // other: true,
        values: locationArry,
        rules: { required: true },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
        },
        defaultValue: "",
      },
      {
        id: 7,
        // heading: "Full Name",
        label: "Zip*",
        name: "zip_code",
        className: "formfild",
        type: "text",
        inputType: "text",
        rules: { required: true, maxLength: 5, minLength: 5 },
        errorMessage: {
          required: "*This Field is Required",
          custom: "Value is Invalid",
          maxLength: "Please provide a valid five-digit ZIP code.",
          minLength: "Please provide a valid five-digit ZIP code.",
        },
      },
    ],
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const scrollToTop = () => {
    if (ref?.current !== "undefined") {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
    // window.addEventListener("scroll", function () {
    //   console.log("scroll!")
    // });
  };
  // const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  // const PrivacyModalOpen = () =>{
  //   setIsPrivacyOpen(true);

  // }
  const privacyModalEvent = () => {
    setCheckPrivacy(true);
    setCheckPrivacyToogle(true);
  };
  const termsModalEvent = () => {
    setCheckTerms(true);
    setCheckTermsToogle(true);
  };
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta name="keywords" content="TMFlow, ANS testing,Autonomic Nervus System, Diabetes Report, Diabetes"></meta>
        <meta property="og:title" content="The number one health risk analysis"></meta>
        <meta property="og:type" content="website" ></meta>
        <meta property="og:image" content="https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/Twitter_banner_design_24-11-2023.jpg"></meta>
        <meta property="og:description" content="This analysis will do a full score of your autonomic and vascular systems giving you potentially life saving information about your state of health. Our exam is non-invasive and only takes 20 minutes after a quick patient enrollment."></meta>

        <meta property="og:image:width" content="1200"></meta>
        <meta property="og:image:height" content="627"></meta>
        <meta property="og:url" content="https://www.diabetesrexburg.com"></meta>
      </Helmet>



      <div className="diabetes_rxbrg_main_wrpr">
        <div className="diabetes_rxbrg_bnnr">
          <div className="common_width">
            <div className="diabetes_rxbrg_bnnr_sub">
              <div className="rxbrg_bnnr_left">
                <div className="dia_txt_wrpr">
                  <div className="diabetes_rxbrg_logo">
                    {/* <img src="https://all-frontend-assets.s3.amazonaws.com/Diabetes-rexburg-decision-doc/dia_rxbrg/decision_doc_logo.webp" /> */}
                    {/* <img src="https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/decision_doc_logo.webp" /> */}

                    <img src="https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/decision_doc_logo.webp" />
                  </div>
                  <div className="diabetes_rxbrg_txt">
                    <h2>
                      <span>Get the Diabetes Report.</span>
                    </h2>
                    <h5>Plus Neuropathy</h5>
                    <h3>Do You Have Diabetes? Do you know its complexity? </h3>
                  </div>

                  <div className="diabetes_rxbrg_bg">
                    <div className="diabetes_rxbrg_friday">
                      <h1>
                        <span className="sky_grd">BLACK FRIDAY</span>
                      </h1>
                      <h2>
                        <span className="sky_grd">SPECIAL OFFER </span>
                      </h2>
                      <p>COME INTO OUR REXBURG TESTING CENTER</p>
                    </div>
                    <div className="diabetes_rxbrg_price">
                      <h5>
                        Usual price: <span>$249</span>
                      </h5>
                      <h6>
                        <span>Special Price: $197</span>
                      </h6>
                    </div>
                    <h3 className="rx_h3">
                      Get 25+ pages of insights and medically valid<br></br>
                      data that you can even share with your doctor!
                    </h3>
                  </div>

                  <div className="hurry_block">
                    <h1>
                      <span>HURRY UP!</span>
                    </h1>
                    <p>
                      This 15-minute non-invasive exam give you vital information on whether you have diabetes, how complex it is, plus the early detection of <span>20+ other debilitating disease states</span>!
                    </p>
                  </div>
                </div>

                <div className="rxbrg_bnnr_arrow">
                  <h3>
                    Whether you are diabetic or may think you are don't wait!
                  </h3>
                  <h1>PAY FOR THIS EXAM ON OUR SITE AND BOOK YOUR </h1>{" "}
                  <br></br>
                  <h1>30 MINUTE APPOINTMENT!</h1>
                </div>
              </div>

              <div className="rxbrg_bnnr_right">
                <div className="rxbrg_bnnr_right_inn" ref={ref}>
                  <div className="rxbrg_bnnr_form_hdr">
                    <h2>
                      {" "}
                      FILL OUT THIS FORM <br></br>TO PURCHASE OUR <br></br>EXAM
                      FOR YOU OR A <br></br>LOVED ONE!{" "}
                    </h2>
                    <p> (Gift option available)</p>
                  </div>

                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    formData={DiabaticsForm}
                  />
                  {formSubmitLoading ? <LinearProgress /> : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="diabetes_rxbrg_block1 rxbrg_padd">
          <div className="common_width">
            <div className="diabetes_rxbrg_block1_sub">
              <div className="diabetes_rxbrg_block1_sub_inn rxbrg_txt rxbrg_1txt">
                <h1>Who We are</h1>
                <div className="diabetes_rxbrg_block1_para">
                  <p>
                    Decision DOC Rexburg is a locally operated testing center.
                    You can find us at the Valley River Shopping Center, 125
                    Valley River Drive, Rexburg, ID, <span>suite #3E</span>.
                    Come in for our non-invasive exam today! It only takes 15
                    minutes and you get 25+ pages of insights and medically
                    valid data that you can even share with your doctor!
                  </p>
                  <p>
                    <span>Decision DOC</span> also maintains a national
                    footprint working with providers and laboratories all over
                    the country. The base medical device that we use is called
                    the TM-Flow. There are thousands of providers that have this
                    device in their practices. The science behind our exam is
                    covered by 5 FDA Clearances. This is advanced medicine and
                    we are bringing it to you right here in Rexburg!
                  </p>
                  <p>
                    Our exam also tests for disease states associated with your
                    nervous system and heart health. In total, we test for early
                    signs of over <span>20 life-risk disease states</span>,
                    including heart attack, potential stroke indicators,
                    diabetes with complications, and other chronic diseases. All
                    in less than 15 minutes!
                  </p>
                </div>
              </div>

              <div className="diabetes_rxbrg_block1_video_main">
                <h1>
                  Watch this video presentation to learn more about the benefits
                  of early diagnosis:
                </h1>
                <div className="diabetes_rxbrg_block1_video_main_blue_bg">
                  <div className="diabetes_rxbrg_block1_video_sub_main">
                    <ReactPlayer
                      playing={true}
                      className="video_sec"
                      light="https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/rxbrg_vdo_image_new.webp"
                      url="https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/Test_video_Decision_Doc.mp4"
                      controls={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="dia_new_sec1_main_wrapper">
              <div className="dia_new_sec1_SUB_wrapper">
                <div className="dia_new_sec1_SUB_sec1_wrapper">
                  <h1>
                    All of the following symptoms may be indicators of disease
                    states that we test for at our center!
                  </h1>
                </div>
                <div className="flx_wrp_ul">
                  <img
                    className="column_abs_img"
                    src="https://all-frontend-assets.s3.amazonaws.com/pece-marketing-diabetes-rexburg/column_abs_img.webp"
                  />
                  <ul className="ul_column_wrpr">
                    <li>Blurred Vision</li>
                    <li>Elevated Blood Sugar</li>
                    <li>Extreme Thirst</li>
                    <li>Frequent Urination</li>
                    <li>Fatigue (Tiredness)</li>
                    <li>Heartburn</li>
                    <li>Increased Hunger</li>
                    <li>Nausea</li>
                    <li>Numbness & Tingling in</li>
                    <li>Hands or Feet</li>
                    <li>Vomiting</li>
                    <li>
                      Angina{" "}
                      <span>
                        (severe chest pain, often spreading to shoulder,
                        arm,back, neck, or jaw)
                      </span>
                    </li>
                  </ul>
                  <ul className="ul_column_wrpr">
                    <li>Chest Pain that goes away with rest</li>
                    <li>Pain in Calves</li>
                    <li>Shortness of Breath</li>
                    <li>Stroke</li>
                    <li>
                      TIA <span>(mini-stroke)</span>
                    </li>

                    <li>Burning Sensations</li>
                    <li>Painful Contact with Socks or Bed Sheets</li>
                    <li>Pebble or Sand-like Sensation in Shoes</li>
                    <li>Stabbing or Electrical Shock Sensation</li>
                  </ul>
                  <ul className="ul_column_wrpr">
                    <li>Pins and Needles Sensation </li>
                    <li>
                      Blood-clot in a vein
                      <span>(Venous Thrombosis)</span>
                    </li>
                    <li>Heart-Attack</li>
                    <li>
                      Irregular heartbeat, too fast/slow{" "}
                      <span>(Atrial Fibrillation)</span>
                    </li>
                    <li>Difficulty Digesting Food</li>
                    <li>Dizziness or Fainting</li>
                    <li>Exercise Intolerance</li>
                    <li>Sexual Difficulties</li>
                    <li>Sweat Abnormalities</li>
                    <li>Urinary Problems</li>
                  </ul>
                  <ul className="ul_column_wrpr">
                    <li>Headache</li>
                    <li>Swelling of Ankles</li>
                    <li>Cold, Clammy, Pale Skin</li>
                    <li>Depression</li>
                    <li>Lightheadedness</li>
                    <li>Lack of Concentration</li>
                    <li>Lack of Energy</li>
                    <li>Rapid, Shallow Breathing</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="diabetes_rxbrg_block1_sub1">
              <div className="diabetes_rxbrg_block1_sub1_flx">
                <div className="rxgbrg_blu_left">
                  <div className="rxgbrg_blu_left_inn">
                    <div className="rxgbrg_blu_left_img">
                      <img src="https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/rxbrg_block2_img.webp" />
                    </div>
                  </div>
                  <div className="diabetes_rxbrg_green">
                    <h1>
                      This analysis will do a full score of your autonomic and
                      vascular systems, giving you potentially life-saving
                      information about your state of health.
                    </h1>
                  </div>
                </div>

                <div className="rxgbrg_blu_rght rxbrg_txt">
                  <h1>
                    OUR QUICK, EASY, AND <br></br>NON-INVASIVE EXAM TESTS FOR
                  </h1>
                  <div className="rxbrg_bullet rxbrg_blu_sec">
                    <p>Heart Attack Risk</p>
                    <p>Stroke risk</p>
                    <p>Diabetes with Complications</p>
                    <p>Cardiovascular Disease(s)</p>
                    <p>Cardiac Autonomic Neuropathy</p>
                    <p>Atherosclerosis</p>
                    <p>Irregular Heart Beat</p>
                    <p>Postpartum Dysfunctions</p>
                    <p>Peripheral Vascular Disease</p>
                    <p>Post Covid Complications</p>
                    <p>
                      Nerve Damage <br></br>
                      <span>
                        (including that which can lead to organ failure)
                      </span>
                    </p>
                    <p>Multiple system dystrophy</p>
                    <p>Pure autonomic failure</p>
                    <p>Artery inflammation and disease</p>
                    <p>Obesity complications</p>
                    <p>Build up of Fat and Cholesterol in Arteries</p>
                    <p>Amyloid Neuropathy</p>
                    <p>Postpartum Disfunction</p>
                    <p>
                      Heart Rate Variability <span>(fluttering heart)</span>
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="diabetes_rxbrg_green">
                <h1>
                  This analysis will do a full score of your autonomic and
                  vascular systems giving you potentially life-saving
                  information about your state of health. Our exam is
                  non-invasive and only takes 15 minutes after a quick patient
                  enrollment.
                </h1>
              </div> */}
            </div>

            <div className="diabetes_rxbrg_block1_sub2 rxbrg_padd_rght">
              <div className="diabetes_rxbrg_block1_sub2_inn rxbrg_txt">
                <h1>
                  THE FOLLOWING ARE THE 8 AREAS OF HEALTH RISK THAT WE EXAMINE{" "}
                </h1>
                <div className="rxbrg_bullet rxbrg_bull_sec">
                  <p>Autonomic Nervous System Dysfunction</p>
                  <p>Insulin Resistance</p>
                  <p>Endothelial Dysfunction.</p>
                  <p>Sudomotor Dysfunction</p>
                  <p>Cardiac Autonomic Neuropathy</p>
                  <p>Cardio- Metabolic Risk</p>
                  <p>Small Fibre Neuropathy</p>
                  <p>Cardio-vascular Disease</p>
                </div>
                <h2>
                  HERE IS A QUICK REVIEW OF THE 8 HEALTH RISK FACTORS THAT OUR
                  ANS TESTING IS BASED ON. YOU WILL FIND THESE TO BE EXTREMELY
                  EASY TO READ AND ACT UPON.
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="diabetes_rxbrg_block2">
          <div className="diabetes_rxbrg_block2_sub">
            <div className="common_width">
              <div className="diabetes_rxbrg_block2_sub_pd">
                <div className="diabetes_rxbrg_block2_sub_inn blu_sec1">
                  <h2>
                    <span className="no">1</span>{" "}
                    <span>
                      AUTONOMOUS NERVOUS SYSTEM DYSFUNCTION RISK - ANSD
                    </span>
                  </h2>

                  <p>
                    Problems with the ANS can range from mild to
                    life-threatening.{" "}
                    <span>
                      Sometimes, only one part of the nervous system is affected
                    </span>
                    . In other cases, the entire ANS is affected. Some
                    conditions are temporary and can be reversed, while others
                    are chronic and will continue to worsen over time. Diseases
                    such as Diabetes or Parkinson’s Disease can cause
                    irregularities with ANS. Problems with ANS regulation often
                    involve organ failure, or the failure of the nerves to
                    transmit a necessary signal.
                  </p>

                  <h2>
                    <span className="no">2</span>{" "}
                    <span>SUDOMOTOR DYSFUNCTION RISK - SudoD</span>
                  </h2>

                  <p>
                    Sudomotor dysfunction testing may indicate to physicians a
                    patient's peripheral nerve and cardiac sympathetic
                    dysfunction.{" "}
                    <span>
                      Neuropathy is a common complication in diabetes mellitus
                      (DM), with 60%-70%
                    </span>{" "}
                    of patients affected over their lifetime. Symptoms of
                    neuropathy are more common than clinical neuropathy.
                    Neuropathy may remain undetected, and progress over time
                    leading to serious complications. The most common associated
                    clinical condition is peripheral neuropathy, affecting the
                    feet. Autonomic nerve involvement is common but probably the
                    most undiagnosed. Low scores in the sudomotor may lead a
                    medical provider to look at clinical neuropathy.
                  </p>

                  <div className="green_btn">
                    <button onClick={() => scrollToTop()}>
                      Book a test for you or your loved one now!{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="diabetes_rxbrg_block2_sub">
            <div className="common_width">
              <div className="diabetes_rxbrg_block2_sub_pd">
                <div className="diabetes_rxbrg_block2_sub_inn blu_sec2">
                  <h2>
                    <span className="no">3</span>{" "}
                    <span>ENDOTHELIAL DYSFUNCTION RISK - EndoD</span>
                  </h2>

                  <p>
                    Current evidence suggests that endothelial function is an
                    integrative marker of the net effects of damage from{" "}
                    <span>
                      traditional and emerging risk factors on the arterial wall
                      and its intrinsic capacity for repair
                    </span>
                    . Endothelial dysfunction, detected as the presence of
                    reduced vasodilating response to endothelial stimuli, has
                    been observed to be associated with major cardiovascular
                    risk factors, such as aging, hyperhomocysteinemia,
                    post-menopause state, smoking, diabetes,
                    hypercholesterolemia, and hypertension.
                  </p>

                  <h2>
                    <span className="no">4</span>{" "}
                    <span>INSULIN RESISTANCE RISK - IR</span>
                  </h2>

                  <p>
                    Insulin resistance is defined clinically as the inability of
                    a known quantity of exogenous or endogenous insulin to
                    increase glucose uptake and utilization in an individual as
                    much as it does in a normal population. Insulin resistance
                    occurs as part of a cluster of cardiovascular metabolic
                    abnormalities commonly referred to as{" "}
                    <span>
                      "The Insulin Resistance Syndrome" or "The Metabolic
                      Syndrome"
                    </span>
                    . This cluster of abnormalities may lead to the development
                    of Type-2 diabetes, accelerated atherosclerosis,
                    hypertension, or polycystic ovarian syndrome depending on
                    the genetic background of the individual developing insulin
                    resistance.
                  </p>

                  <div className="green_btn">
                    <button onClick={() => scrollToTop()}>
                      Book a test for you or your loved one now!{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="diabetes_rxbrg_block2_sub">
            <div className="common_width">
              <div className="diabetes_rxbrg_block2_sub_pd">
                <div className="diabetes_rxbrg_block2_sub_inn blu_sec3">
                  <h2>
                    <span className="no">5</span>{" "}
                    <span>CARDIOMETABOLIC RISK - CMR</span>
                  </h2>

                  <p>
                    The specific factors that can cause this increased risk
                    include obesity (particularly central), hyperglycemia,
                    hypertension, insulin resistance, and dyslipoproteinemia.
                    When patients have one or more risk factors and are
                    physically inactive or smoke, the cardiometabolic risk is
                    increased even more.{" "}
                    <span>
                      Medical conditions that often share the above
                      characteristics, such as type 2 diabetes,
                    </span>{" "}
                    can also increase cardiometabolic risk. The primary focus of
                    cardiometabolic risk treatment is the management of each
                    high-risk factor, including dyslipoproteinemia,
                    hypertension, and diabetes. The management of these subjects
                    is based principally on lifestyle measures, but various
                    antihypertensive, lipid-lowering, insulin-sensitizing,
                    anti-obesity and antiplatelet drugs could be helpful in
                    reducing cardiometabolic risk.
                  </p>

                  <h2>
                    <span className="no">6</span>{" "}
                    <span>SMALL FIBER NEUROPATHY RISK - SFN</span>
                  </h2>

                  <p>
                    A small fiber neuropathy occurs when damage to the
                    peripheral nerves predominantly or entirely affects the
                    small myelinated fibers or unmyelinated C fibers. The
                    specific fiber types involved in this process include both
                    small somatic and autonomic fibers. The sensory functions of
                    these fibers include thermal perception and nociception.
                    These fibers are involved in many autonomic and enteric
                    functions.
                  </p>

                  <div className="green_btn">
                    <button onClick={() => scrollToTop()}>
                      Book a test for you or your loved one now!{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="diabetes_rxbrg_block2_sub">
            <div className="common_width">
              <div className="diabetes_rxbrg_block2_sub_pd">
                <div className="diabetes_rxbrg_block2_sub_inn blu_sec4">
                  <h2>
                    <span className="no">7</span>{" "}
                    <span>CARDIAC AUTONOMIC NEUROPATHY RISK - CAN</span>
                  </h2>

                  <p>
                    High blood glucose levels over a period of years may cause a
                    condition called autonomic neuropathy. This is damage to the
                    nerves that control the regulation of involuntary function.{" "}
                    <span>
                      When nerve damage affects the heart, it is called cardiac
                      autonomic neuropathy (CAN)
                    </span>
                    . CAN encompasses damage to the autonomic nerve fibers that
                    innervate the heart and blood vessels, resulting in
                    abnormalities in heart rate control, vascular dynamics, and
                    the body's ability to adjust blood pressure. CAN is a
                    significant cause of morbidity and mortality associated with
                    a high risk of cardiac arrhythmias and sudden death.
                  </p>

                  <h2>
                    <span className="no">8</span>{" "}
                    <span>
                      PLETHYSMOGRAPHY CARDIOVASCULAR DISEASE RISK - PTG CVD
                    </span>
                  </h2>

                  <p>
                    The PTG CVD risk factor is the combined total of the other
                    seven risk factors assessments. It takes into consideration
                    the cardiovascular as well as the autonomic nervous system
                    (ANS) measurements.
                  </p>

                  <div className="green_btn">
                    <button onClick={() => scrollToTop()}>
                      Book a test for you or your loved one now!{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

        {/* <div className="footer_rxbrg">
          <div className="common_width">
            <div className="foooter_rxbrg_bdy">
              <p>
                <a onClick={() => termsModalEvent()}>Terms & Conditions</a> |{" "}
                <a onClick={() => privacyModalEvent()}>Privacy Policy</a>{" "}
                Copyright © 2023
              </p>
            </div>
          </div>
        </div> */}
      </div>
      {/* {checkTerms ? (
        <TermsModal
          page="health"
          popuptoogle={checkTermsToogle}
          popuptooglefunc={setCheckTermsToogle}
        />
      ) : (
        ""
      )} */}
      {/* {checkPrivacy ? (
        <PrivacyModal
          page="health"
          popuptoogle={checkPrivacyToogle}
          popuptooglefunc={setCheckPrivacyToogle}
        />
      ) : (
        ""
      )} */}

      {/* snackBarMsg */}
      {/* snakbaropen */}
      <Snackbar
        className="loginSnack Snackbar_center"
        autoHideDuration={6000}
        open={snakbaropen}
        message={snackBarMsg}
        key="bottomcenter"
      />

      <div className="top-to-btm">
        {" "}
        {showTopBtn && (
          <a className="icon-position icon-style" onClick={goToTop}>
            <ArrowUpwardIcon />
          </a>
        )}{" "}
      </div>
    </>
  );
};
export default Diabatics;
