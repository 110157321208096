import React from 'react'
import "../Privacy-Policy/privacy.css";
import Footer from '../../Layout/Footer';

function Privacy() {
    return (
        <>
            <div className='privacy_main_wrapper'>
                <div className='common_width'>
                    <div class="diabetes_rxbrg_logo">
                        <img src="https://d37pbainmkhx6o.cloudfront.net/pece-marketing-diabetes-rexburg/decision_doc_logo.webp" />

                    </div>
                    <div className='privacy_sub_wrapper'>
                        <div className='privacy_sub_sec_wrapper'>
                            <h2>Privacy Policy</h2>
                            <p>Welcome to Decision Doc Rexburg, ("we," "our," "us"). We respect your privacy and want to ensure you understand how we handle the information you share with us.</p>
                            <p>This Privacy Policy outlines the use of your 'Personally Identifiable Information' (PII) online by Decision Doc Rexburg. PII refers to information that can identify, contact, or locate an individual, either on its own or when combined with other data. As a Data Controller under the General Data Protection Regulation, we process PII.</p>
                            <p>Please note that we may update this Privacy Policy without notice, and your use or viewing of diabetesrexburg.com implies acceptance, whether or not you've read the policy.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>California Online Privacy Protection Act (CalOPPA)</h1>
                            <p>In compliance with CalOPPA, a state law mandating privacy policies for websites collecting PII from California consumers, we make the following commitments:</p>
                            <ul>
                                <li>Users can visit our site anonymously.</li>
                                <li>Our Privacy Policy is accessible on our homepage or the first significant page after entering www.diabetesrexburg.com.</li>
                                <li>The Privacy Policy link, containing the word 'Privacy,' is easily found on the specified page.</li>
                                <li>Personal Identifiable Information</li>
                            </ul>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>What information do we collect?</h1>
                            <p>When you order or sign up on our site, we may request information to personalize your online experience. This may include your name, email address, mailing address, phone number, credit card details, or other relevant information.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>When do we collect information?</h1>
                            <p>We collect information when you place an order, subscribe to a newsletter, navigate the website, or provide details through surveys or marketing communications.</p>
                            <p>You can choose not to provide certain information, but this may limit your access to specific features on the site.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>How do we use your information?</h1>
                            <p>We use collected information for the following purposes:</p>
                            <ul>

                                <li>Name: To address you appropriately and verify your identity.</li>
                                <li>Email Address: As a point of contact and for sharing information and materials.</li>
                                <li>Mailing Address: For shipping purchased orders.</li>
                                <li>Phone Number: To facilitate efficient order deliveries.</li>
                                <li>Credit Card Information: To securely process your orders.</li>
                            </ul>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>How do we protect your information?</h1>
                            <p>Your personal information is secured behind restricted networks, accessible only by a limited number of individuals with special access rights. These individuals are contractually obligated to maintain confidentiality. Sensitive/credit information is encrypted using Secure Socket Layer (SSL) technology.</p>
                            <p>Transactions are processed through a secure gateway provider, and credit card details are neither stored nor processed on our servers.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>General Data Protection Regulation (GDPR)</h1>
                            <p>In compliance with the GDPR, you have the right to access, correct, delete, or withdraw consent to the use of your information. If you believe your rights have been violated, contact us at <a href='mailto:support@decisiondoc.com'>support@decisiondoc.com</a>.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Retention</h1>
                            <p>We retain your information to respond to inquiries, process orders, and share relevant information about ordered products. For mailing list subscriptions, we retain information as long as you remain subscribed.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Tracking</h1>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Analytics</h1>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>This website collects personal data for site analytics, including:</h1>
                            <ul>
                                <li>Browser, network, and device information.</li>
                                <li>Web pages visited prior to entering this website.</li>
                                <li>IP address.</li>
                                <li>Details of your site usage (clicks, internal links, pages visited, scrolling, searches, timestamps).</li>
                                <li>This information is shared with Decision Doc Rexburg, our website analytics provider, to understand site traffic and activity.</li>
                            </ul>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Cookies</h1>
                            <p>This website uses cookies and similar technologies. Functional and required cookies are always used for secure website serving. Analytics and performance cookies are employed only when you acknowledge our cookie banner.</p>
                            <p>Google Analytics, a third-party vendor, collects data on demographics and interests. Our activity complies with Google Analytics Terms of Service.
                            </p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Do Not Track signals</h1>
                            <p>We honor Do Not Track signals and refrain from tracking, planting cookies, or using advertising when a DNT browser mechanism is in place.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Third-party disclosure</h1>
                            <p>We do not sell, trade, or transfer PII to outside parties without advance notice, excluding website hosting partners and others assisting in website operation. Information may be released to comply with the law, enforce site policies, or protect rights, property, or safety.</p>
                            <p>Non-personally identifiable information may be provided to other parties for marketing, advertising, or other uses.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Opting out</h1>
                            <p>You can set preferences for Google advertising using the Google Ad Settings page or opt out via the Network Advertising Initiative Opt Out page or Google Analytics Opt Out Browser add-on.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Children Online Privacy Protection Act (COPPA)</h1>
                            <p>We comply with COPPA, refraining from marketing to or allowing third-parties to collect PII from children under 13 without parental consent.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Fair Information Practices</h1>
                            <p>In the event of a data breach, we commit to notifying you via email within 7 business days. The Individual Redress Principle allows individuals to pursue legal rights against data collectors and processors violating the law.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Unsubscribe/Opt-out</h1>
                            <p>You can opt-out of future emails by following instructions at the bottom of each email.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>CAN-SPAM Act</h1>
                            <p>We adhere to the CAN-SPAM Act, setting rules for commercial email, ensuring recipients can unsubscribe, and indicating penalties for violations.</p>
                        </div>
                        <div className='privacy_sub_sec_wrapper'>
                            <h1>Contacting Us</h1>
                            <p>For questions about this Privacy Policy or your PII rights, contact us at <a href='mailto:support@decisiondoc.com'>support@decisiondoc.com</a> with "Privacy Policy" in the subject line.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Privacy
