import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// export const login = createAsyncThunk('user/login', async (body) => {
//     console.log('body====>', process.env)
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(body)
//     }
//     const response = await fetch(`${process.env.REACT_APP_API_URL}profile/login`, requestOptions);
//     const res = await response.json();
//     return res;
// })
// // export const getipInfo = createAsyncThunk("user/getipInfo", async () => {
// //     console.log("process.env==>", process.env);

// //     const ipresponse = await fetch(process.env.REACT_APP_API_URL_IP);
// //     const ipInfo = await ipresponse.json();
// //     console.log('ipInfo==>', ipInfo);
// //     // console.log('ipresponse==>', ipresponse);
// //     ipInfo.browser = getbrowserinfo();
// //     return ipInfo;
// // });

// export const userLogout = createAsyncThunk("user/logout", async (body) => {
//     console.log("process.env==>", process.env);

//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(body)
//     }
//     const response = await fetch(`${process.env.REACT_APP_API_URL}profile/logout`, requestOptions);
//     const res = await response.json();
//     return res;
// });

// export const getLeadInfo = createAsyncThunk("get/leadinfo", async (body) => {
//     console.log("process.env==>", process.env);

//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(body)
//     }
//     const response = await fetch(`${process.env.REACT_APP_API_URL}calendar-manage/fetch-lead`, requestOptions);
//     const res = await response.json();
//     return res;
// });

// export const addBrowserReport = createAsyncThunk("add/browser-report", async (body) => {
//     console.log("process.env==>", process.env);

//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(body)
//     }
//     const response = await fetch(`${process.env.REACT_APP_API_URL}api2/add-browser-report`, requestOptions);
//     const res = await response.json();
//     return res;
// });




// //////////////////////////// For user list ////////////////////////
// export const getdataforusernext = createAsyncThunk(
//     "dashboard/next",
//     async (reqBody) => {
//         const requestOptions = {
//             method: "POST",
//             headers: { "Content-Type": "application/json" },
//             body: JSON.stringify(reqBody),
//         };
//         const response = await fetch(
//             `${process.env.REACT_APP_API_URL}api/user-login`,
//             requestOptions
//         );
//         console.log("Next 222 step", response)
//         const res = await response.json();

//         return res;
//     }
// );


// export const forgotPassword = createAsyncThunk("forgotpassword", async (body) => {
//     console.log("<<<< payload goes here >>>>>>>>", process.env);
//     console.log("process.env==>", process.env);

//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ "email": body.email })
//     }
//     const response = await fetch(`${process.env.REACT_APP_API_URL}profile/forgot-password`, requestOptions);
//     const res = await response.json();
//     return res;
// });




// export const reportIsuue = createAsyncThunk("reportanissue", async (body) => {
//     console.log("<<<< payload goes here >>>>>>>>", process.env);
//     console.log("process.env==>", process.env);

//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(body)
//     }
//     const response = await fetch(`${process.env.REACT_APP_API_URL}users/report-account-access`, requestOptions);
//     const ress = await response.json();
//     return ress;
// });




const RewardLoginReducer = createSlice({
    name: 'RewardLoginReducer',
    initialState: {
        ipInfo: {},
        errorMessage: null,
        userInfo: {},
        token: null,
        userType: null,
        last_login_time: null,
        loading: false,
        message: null,
        isLoggedIn: false,
        userlistasyncdata: {},
        success: false,
        error: false,
        websiteinfo: null,
        websiteContent: null,
        loginRouteClicked: false,
        leadInfo: {},
        resetPassMSg: null,
        user_name: null,
        user_slug: [],
        resMessage: false,
    },
    reducers: {
        clearMessageIsLoggedIn(state, action) {
            state.message = null;
            state.isLoggedIn = null;
        },
        setloginError(state, action) {
            state.errorMessage = null
        },
        setloginRoute(state, action) {
            console.log("setloginRoute action", action)
            state.loginRouteClicked = action.payload
        },
        setLogedinUserInfo(state, action) {
            console.log("state from setLogedinUserInfo==>", action.payload);
            // console.log("action.payload from setLogedinUserInfo==>",type_val);
            if (action.payload ) {
                state.userInfo = action.payload;
                state.isLoggedIn = true;
               

            }
            // console.log("state.userInfo setLogedinUserInfo==>", state.userInfo);
        },
        setUserSlugBlank(state, action) {
            state.user_slug = []
        },

        setUserName(state, action) {
            // console.log("state from setLogedinUserInfo==>", state);
            // console.log("action.payload from setLogedinUserInfo==>", state, action.payload);
            if (action.payload !== undefined && action.payload.username != null) {
                state.user_name = action.payload.username;

            }
            // console.log("state.userInfo setLogedinUserInfo==>", state.userInfo);
        },
        logOut(state, action) {
            // console.log("state from logOut==>", state);
            // console.log("payload from logOut==>", action);
            state.userInfo = {};
            state.token = null;
            state.last_login_time = null;
            state.isLoggedIn = null;
            state.errorMessage = null;
            // console.log("state from logOut after set==>", state);
        },
        setUserInitialInfo(state, action) {
            console.log()
        },
        setIpinfo(state, action) {
            state.ipInfo = action.payload
        },
        setWebsiteContent(state, action) {
            state.websiteContent = action.payload
        },
        setLeadinfo(state, action) {
            state.leadInfo = action.payload
        },
        setSignupContent(state, action) {
            state.userInfo = action.payload
        }
    },
    extraReducers: {
        // [getipInfo.fulfilled]: (state, action) => {
        //     console.log("action of getipInfo.fulfilled==>", action);
        //     state.ipInfo = action.payload;
        //     console.log("state of getipInfo.fulfilled==>", state.ipInfo);
        // // },
        // [login.pending]: (state, action) => {
        //     state.loading = true;
        //     state.error = false;
        //     state.message = null;

        // },
        // [login.fulfilled]: (state, action) => {
        //     state.loading = false;
        //     console.log('action.payload.item', action.payload);
        //     if (action.payload.status === 'success') {
        //         state.token = action.payload.token;
        //         state.error = false;
        //         state.userInfo = action.payload?.item;
        //         state.userType = action.payload?.item?.type;
        //         state.isLoggedIn = true;
        //         state.success = true;
        //         state.last_login_time = action.payload?.item?.last_login_time
        //         state.message = action.payload?.message ? action.payload.message : 'Login Successful';
        //     }
        //     if (action.payload.status === 'error') {
        //         state.isLoggedIn = false;
        //         state.loading = false;
        //         state.userInfo = null;
        //         state.error = true;
        //         state.message = action.payload?.message ? action.payload.message : 'Something Went Wrong!';
        //     }
        // },
        // [login.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.message = null;

        // },



        // // ############## ---- logOut ---- ######################

        // [userLogout.pending]: (state, action) => {
        //     state.loading = true;
        //     state.message = null;

        // },
        // [userLogout.fulfilled]: (state, action) => {
        //     // console.log("logout action ", action)
        //     state.loading = false;
        //     if (action.payload.status === 'success') {
        //         state.userInfo = {};
        //         state.token = null;
        //         state.last_login_time = null;
        //         state.isLoggedIn = null;
        //         state.errorMessage = null;
        //         state.success = true;
        //         state.message = action.payload.message;
        //     }
        //     if (action.payload.status === 'error') {
        //         state.isLoggedIn = false;
        //         state.message = action.payload.message ? action.payload.message : 'Something Went Wrong!';

        //     }
        // },
        // [userLogout.rejected]: (state, action) => {
        //     state.loading = false;
        // },




        // ////////////////////////////////userlistasync for dashboard////////////////
        // [getdataforusernext.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [getdataforusernext.fulfilled]: (state, action) => {
        //     console.log("action", action);
        //     if (action.payload.status === "success") {
        //         state.userlistasyncdata = action.payload.results.res;
        //         state.loading = false;
        //         state.submitting = false;
        //     }
        //     if (action.payload.status === "error") {
        //         state.errorMessage = action.payload.message;
        //         state.message = action.payload.message;
        //     }
        // },
        // [getdataforusernext.rejected]: (state, action) => {
        //     state.loading = false;
        // },





        // [getLeadInfo.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [getLeadInfo.fulfilled]: (state, action) => {
        //     console.log("action getLeadInfo", action);
        //     if (action.payload.status === "success") {
        //         state.loading = false;
        //         state.success = true;
        //         state.leadInfo = action.payload.results
        //     }
        //     if (action.payload.status === "error") {
        //         state.errorMessage = action.payload.message;
        //         state.message = action.payload.message;
        //         state.loading = false;

        //     }
        // },
        // [getLeadInfo.rejected]: (state, action) => {
        //     state.loading = false;
        // },





        // [addBrowserReport.pending]: (state, action) => {
        //     state.loading = true;
        // },
        // [addBrowserReport.fulfilled]: (state, action) => {
        //     console.log("action getLeadInfo", action);
        //     // if (action.payload.status === "success") {
        //     state.loading = false;
        //     //     state.success = true;
        //     //     state.leadInfo = action.payload.results.res[0]
        //     // }
        //     // if (action.payload.status === "error") {
        //     //     state.errorMessage = action.payload.message;
        //     //     state.message = action.payload.message;
        //     // }
        // },
        // [addBrowserReport.rejected]: (state, action) => {
        //     state.loading = false;
        // },



        // // ------- Forgot password -------
        // [forgotPassword.pending]: (state, action) => {
        //     state.loading = true;
        //     state.resetPassMSg = null;
        // },
        // [forgotPassword.fulfilled]: (state, action) => {
        //     console.log("action getLeadInfo", action);
        //     // if (action.payload.status === "success") {
        //     state.loading = false;
        //     state.resetPassMSg = action.payload.message;
        //     // }
        // },
        // [forgotPassword.rejected]: (state, action) => {
        //     state.loading = false;
        // },



        // // ------- reportIsuue -------
        // [reportIsuue.pending]: (state, action) => {
        //     state.loading = true;
        //     state.resMessage = false
        //     // state.resetPassMSg = null;
        // },
        // [reportIsuue.fulfilled]: (state, action) => {
        //     // console.log("action getLeadInfo", action);
        //     // if (action.payload.status === "success") {
        //     state.loading = false;
        //     state.resMessage = true

        //     // state.resetPassMSg = action.payload.message;
        //     // }
        // },
        // [reportIsuue.rejected]: (state, action) => {
        //     state.loading = false;
        //     state.resMessage = false

        // },
        // ----------------------------



    }
})

export const { clearMessageIsLoggedIn, setUserName, logOut, setLogedinUserInfo, setIpinfo, setWebsiteContent, setloginRoute, setLeadinfo, setSignupContent, setUserSlugBlank } = RewardLoginReducer.actions;

export default RewardLoginReducer.reducer;

