import React, { useEffect, useMemo, useState } from "react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "../Diabatics/DiabaticsPaymant.css";
import { Snackbar } from "@mui/material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PRIMARY_KEY);

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [snakbaropen, setSnakbarOpen] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(null);

  useEffect(() => {
    console.log("getCardInputValue cardDetails savePayment", props);
    if (props && props.getCardInputValue && props.getCardInputValue.clicked) {
      handleSubmit();
    }
  }, [props]);

  useEffect(() => {
    if (paymentSuccess) {
      props.getCardInputValue.paymentID(paymentSuccess);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    if (paymentError) {
      props.getCardInputValue.paymentID(paymentError);
    }
  }, [paymentError]);

  useEffect(() => {
    if (snakbaropen) {
      setTimeout(() => {
        setSnakbarOpen(false);
      }, 2000);
    }
  }, [snakbaropen]);

  const handleSubmit = async () => {
    // event.preventDefault();
    console.log("event handleSubmit savePayment");

    if (!stripe || !elements) {
      return;
    }
    const billingDetails = {
      name: props.getCardInputValue.paymentObjectData.billing_name,
      email: props.getCardInputValue.paymentObjectData.billing_email,
      address: {
        city: props.getCardInputValue.paymentObjectData.billing_city,
        line1: props.getCardInputValue.paymentObjectData.address_line_1,
        state: props.getCardInputValue.paymentObjectData.billing_state,
        postal_code: props.getCardInputValue.paymentObjectData.billing_zip,
      },
    };

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails,
      });
      if (error) {
        console.log("savePayment error", error);
        setSnackBarMsg(error.message);
        setPaymentSuccess(null);
        setSnakbarOpen(true);
        setPaymentError(error);
      } else {
        setPaymentSuccess(paymentMethod);
        setSnackBarMsg(null);
        // setSnakbarOpen(true);
        // setSnackBarMsg("Payment Successfull");
        console.log("PaymentMethod:", paymentMethod);
        // You can send paymentMethod.id to your server to complete the payment
      }
    } catch (error) {
      setSnackBarMsg("An error occurred. Please try again.");
      setPaymentSuccess(null);
    }
  };
  const inputStyle = {
    iconColor: "#c4f0ff",
    color: "#ff0",
    fontWeight: "500",
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#87BBFD",
    },
  };
  const getValues = (e) => {
    console.log("getValues ", e);
    if (e) {
      if (
        e.error &&
        (e.error.code === "invalid_expiry_year" ||
          e.error.code === "incomplete_cvc")
      ) {
        if (
          props &&
          props.getCardInputValue &&
          props.getCardInputValue.clicked &&
          e.error.message
        ) {
          setSnakbarOpen(true);
          setSnackBarMsg(e.error.message);
        }
      }
      if (e.error === undefined) {
        console.log("getValues ", e);
        props.getCardInputValue.cardvalidated(true);
      }
    }
  };
  const options = useMemo(() => ({
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#064487",
        },
        "::placeholder": {
          color: "#064487",
        },
        padding: "25px",
        lineHeight: "50px",
        backgroundColor: "#fff",
      },
      invalid: {
        color: "#fff",
      },
    },
  }));
  return (
    // <form onSubmit={handleSubmit}>
    <>
      <div className="stripeCard">
        {/* Card Details44 */}
        <div className="custom-card-element">
          <CardElement
            className="cardElementClass"
            options={{
              style: {
                base: {
                  iconColor: "#c4f0ff",
                  color: "#000",
                  fontWeight: "500",
                  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                  fontSize: "16px",
                  fontSmoothing: "antialiased",
                  ":-webkit-autofill": {
                    color: "#fce883",
                  },
                  "::placeholder": {
                    color: "#064487",
                  },
                  padding: "25px",
                  lineHeight: "50px",
                  backgroundColor: "#fff",
                },
                invalid: {
                  color: "red",
                },
              },
              disableLink: true,
              hidePostalCode: true,
            }}
            onChange={getValues}
          />
          <Snackbar
            className="loginSnack Snackbar_center"
            autoHideDuration={3000}
            open={snakbaropen}
            message={snackBarMsg}
            key="bottomcenter"
          />
        </div>
      </div>
      {/* <button type="submit" disabled={!stripe}>
        Pay
      </button> */}
      {/* {paymentError && <div>{paymentError}</div>}
        {paymentSuccess && <div>Payment successful!</div>} */}
    </>
    // </form>
  );
};

const PaymentComponent = (props) => {
  console.log("PaymentComponent savePayment", props);
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm getCardInputValue={props} />
    </Elements>
  );
};

export default PaymentComponent;
