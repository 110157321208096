import { Backdrop, Box, Fade, Modal, Typography } from '@mui/material';
import { style } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import CloseIcon from '@mui/icons-material/Close';

import './modal.css'

const PrivacyModal = (props) => {
    // {/*################*/}####### Modal Open Close Section {/*################*/}#
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(false);
    if (props) {
        console.log("Props", props)
        // setOpen(true)
    }
    const modalClose = () => {
        props.popuptooglefunc(false);
    }
    //{/*################*/}{/*################*/}{/*################*/}{/*################*/}###
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modalPop copyRightSection_modal"
                open={props.popuptoogle}
                onClose={modalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Box sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                }}>
                    <span className='close' onClick={() => modalClose()}><CloseIcon/></span>
                    {/* <Typography id="transition-modal-title" variant="h3" component="h2">
                        Privacy & Policy
                    </Typography> */}
                    <Typography className='typo' id="transition-modal-description" sx={{ mt: 2 }}>
                        <div class="pp_txt">
                        Coming Soon!
                        </div>
                       
                    </Typography>
                </Box>
            </Modal>

        </>
    )
}

export default PrivacyModal;